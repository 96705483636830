import {Directive} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";

@Directive({
  selector: '[numbersOnly]'
})
export class NumberOnlyDirective {

  constructor(private ngControl: NgControl) {
    this.trimValueAccessor(this.ngControl.valueAccessor)
  }

  trimValueAccessor(valueAccessor: ControlValueAccessor) {
    const original = valueAccessor.registerOnChange;

    valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
      return original.call(valueAccessor, (value: any) => {
        return fn(typeof value === 'string' ? value.replace(/[^0-9]*/g, '').trim() : value);
      });
    };
  }

}
