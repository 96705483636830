<div class="login-model action---box bg-brand-color border-radius-16 ">
  <div class="modal-header justify-content-center align-items-start">
    <div class="me-3">
      <button
        type="button"
        class="btn-close"
        (click)="close()"
      ></button>
    </div>
  </div>
  <div class="modal-body">
    <div class="position-relative">
      <img class="bg-right--img" src="assets/images/bg/cart-bg.png" alt=""/>
      <div class="py-32 px-24">
        <div class="position-relative">
          <div class="text-center">
            <div class="action--form">
              <form class="login-form" (ngSubmit)="!loginForm.invalid && submitForm()" novalidate
                    [formGroup]="loginForm">
                <h3 class="ff-dmsans fs-24 primary_90 font-weight-bold mb-3">
                  {{'Kwot_account_sign_in'| translate}}
                </h3>
<!--                <kwot-email-phone [formGroup]="loginForm" type="sign_in"-->
<!--                                  (onTypeChange)="changeLoginType($event)"></kwot-email-phone>-->
                  <div class="form-group">
                    <div class="d-flex justify-content-between">
                      <label class="mb-0 ms-2 fs-12 fw-bold">{{'email' | translate}}</label>
                      <ng-container *ngIf="(form.email.dirty || form.email.touched) && form.email.errors">
                        <label *ngIf="form.email.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
                          {{ 'errors.is_required' | translate: {field: 'email' | translate} }}</label>
                        <label *ngIf="form.email.errors.pattern" class="text-danger mb-0 me-2 fs-10 fw-bold">
                          {{ 'errors.enter_valid' | translate: {field: 'email_address' | translate} }}</label>
                      </ng-container>
                    </div>
                    <input type="text" class="form-control br-16" formControlName="email"
                           placeholder="{{'placeholder.type' | translate: {field: 'email' | translate | lowercase } }}">
                  </div>
<!--                <ng-container *ngIf="appConfig.type !== 'vendor' || type !== 'registration'">-->
<!--                  <div class="col-md-12 text-start type-switch fw-bold fs-14 ps-1 mt-1 mb-3"-->
<!--                       *ngIf="!isRegisterWithSocialAccount">-->
<!--                    <span class="cursor-pointer" (click)="changeLoginType(!isRegisterWithPhoneNumber)">-->
<!--                      {{isRegisterWithPhoneNumber ?-->
<!--                      ('use' | translate: {field: 'email' | translate | lowercase})-->
<!--                      : ('use' | translate: {field: 'mobile_number' | translate | lowercase})}}-->
<!--                    </span> {{ 'for_type' | translate: {type: ((type || 'registration') | translate)} }}-->
<!--                  </div>-->
<!--                </ng-container>-->

                <div class="form-group">
                  <div class="position-relative">
                    <div class="d-flex justify-content-between">
                      <label class="mb-0 ms-2 fs-12 fw-bold">{{
                        'password' | translate
                        }}</label>
                      <ng-container
                        *ngIf="
                                    (form.password.dirty ||
                                      form.password.touched) &&
                                    form.password.errors
                                  "
                      >
                        <label
                          *ngIf="form.password.errors.required"
                          class="text-danger mb-0 me-2 text-end fs-10 fw-bold"
                        >
                          {{
                          'errors.is_required'
                            | translate
                            : {field: 'password' | translate}
                          }}</label
                        >
                      </ng-container>
                    </div>
                    <div class="position-relative">
                      <input
                        [type]="showPassword ? 'text' : 'password'"
                        formControlName="password"
                        class="form-control br-16"
                        placeholder="{{
                                    'enter_your_field'
                                      | translate
                                        : { field: 'password' | translate }
                                  }}"
                      />
                      <span class="cursor-pointer eye-cond">
                      <img
                        [ngClass]="{ 'd-none': showPassword }"
                        (click)="showPassword = true"
                        src="assets/images/icons/open-eye-icon.svg"
                        alt=""
                      />
                      <img
                        [ngClass]="{ 'd-none': !showPassword }"
                        (click)="showPassword = false"
                        src="assets/images/icons/close-eye-icon.svg"
                        alt=""
                      />
                    </span>
                    </div>
                  </div>
                </div>
                <div class="text-left">
                  <a
                    class="brand-secondary fs-14 fw-bold"
                    [routerLink]="['/', 'user', 'forgot-password']"
                    (click)="bsModalRef?.hide()"
                  >{{ 'forgot_my_password' | translate }}
                  </a>
                </div>
                <button
                  type="submit"
                  class="my-3 btn btn-action"
                  [ngClass]="{
                              'btn-disabled': loginForm.invalid,
                              'btn-action': loginForm.valid
                            }"
                >
                  {{ 'sign_in' | translate }}
                </button>

                <div class="mt-2">
                  <div class="sign-up text-center">
                    <p class="sign-up-link fw-bold">
                      {{ 'dont_have_account' | translate }}
                      <a [routerLink]="['/', 'user', 'register']" (click)="bsModalRef?.hide()">{{
                        'register' | translate
                        }}</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
