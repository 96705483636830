import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {Routes, RouterModule} from '@angular/router';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {
  APP_CONFIG,
  HttpAuthInterceptService
} from '@kwot/app-config';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from "@kwot/shared";
import {NoAuthGuard} from "@kwot/buyer-layout";

const routes: Routes = [
  {path: '', redirectTo: 'user/home', pathMatch: 'full'},
  {
    path: 'user',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@kwot/auth').then((module) => {
            module.AuthModule.environment = environment;
            return module.AuthModule;
          }),
        canActivate: [NoAuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('@kwot/buyer-shared').then((module) => module.BuyerSharedModule)
      }
    ],
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    {provide: APP_CONFIG, useValue: environment},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
