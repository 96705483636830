import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LoaderService, LocalstorageService} from "@kwot/app-config";
import {MeasurementsService} from "../services/measurements.service";
import {
  SaveMeasurements, SaveMeasurementsError, SaveMeasurementsSuccess,
} from "./measurement.actions";
import {catchError, map, of, switchMap} from "rxjs";

@Injectable()
export class MeasurementEffects {
  constructor(
    private actions$: Actions,
    private loaderService: LoaderService,
    private measurementsService: MeasurementsService,
    private ls: LocalstorageService
  ) {
  }

  saveMeasurements$ = createEffect(() => this.actions$.pipe(
    ofType(SaveMeasurements),
    switchMap((action) => {
      this.loaderService.show();
      return this.measurementsService.saveMeasurements(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            let user = this.ls.updateUserKey('get');
            if (user) {
              user.measurements = resp.data;
              this.ls.updateUserKey('store', user);
            }
            return SaveMeasurementsSuccess({measurements: resp.data});
          }
          return SaveMeasurementsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(SaveMeasurementsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))
}
