<div class="rfq-model mt-2">
  <div class="modal-header justify-content-center">
    <button type="button" class="btn-close m-0" (click)="bsModalRef?.hide()"></button>
    <h3 class="modal-title fs-24 fw-bold text-center mb-0"> {{ 'request_for_quotation' | translate }} </h3>
  </div>
  <div class="modal-body">
    <p class="desc fs-18 text-center mb-0"> {{ 'negotiate_the' | translate }} {{isProduct ? ('product_price' | translate) : ('shipping_cost' | translate)}} {{ 'with_the_vendor' | translate }} </p>
    <div class="cart-image mt-4">
      <form [formGroup]="rfqForm" novalidate>
        <div class="product-price-details">
          <div class="row">
            <div class="product-data mb-4" *ngIf="isProduct">
              <div class="img-wrapper">
                <img
                  class="img-fluid"
                  [lazyLoad]="getImage(product, 'small')"
                  defaultImage="assets/images/loaders/skeleton_loader.gif"
                  alt=""
                />
              </div>
              <div class="w-100">
                <div class="product-name mb-3">
                  <p class="fs-16 mb-0">{{product.productName}}</p>
                  <kwot-currency
                    [price]="selectedPrice?.price" [isB2B]="true" [item]="product"
                    type="product"
                    [hasField]="false" [platformFee]="true">
                  </kwot-currency>
                </div>
                <kwot-selected-variation
                  [product]="product">
                </kwot-selected-variation>
              </div>
            </div>
            <div class="col-md-6" *ngIf="!isProduct">
              <div class="rfq_method mt-2 mb-2">
                <div class="mb-2">{{ 'select_rfq_type' | translate }}</div>
                <div class="content-wrapper">
                  <div class="radio-details">
                    <div class="status d-flex flex-wrap">
                      <div class="action-radio-box category-radio me-2 form-check mb-0 ps-0"
                           *ngFor="let data of checkBoxData">
                        <input type="radio" name="refType" value="{{data.value}}"
                               [checked]="selectedValue === data.value"
                               (click)="filterStatusValues($event)">
                        <button class="btn-transparent radio-btn fs-12 fw-bold">{{data.name}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 form-group" *ngIf="selectedValue === 'public'">
              <div class="mb-2">{{'product_name' | translate}}</div>
              <input class="form-control form-text-area resize-none"
                     placeholder="Enter product name" formControlName="productName" />
            </div>
            <div [ngClass]="{'col-md-12' : !isProduct , 'col-md-6' : isProduct}">
              <div class="mb-2">{{ 'quantity' | translate }}</div>
              <div class="q-plus-minus ms-3">
                  <span class="minus me-1" (click)="reduceQty()" [ngClass]="{disabled: rfqForm?.value?.qty === 1}">
                      -
                  </span>
                <input type="number" class="num" formControlName="qty" (input)="updateValues()"/>
                <span class="minus ms-1" (click)="increaseQty()" [ngClass]="{disabled: rfqForm?.value?.qty <= 0}">
                      +
                  </span>
              </div>

              <div class="col-md-6 mt-2" *ngIf="selectedValue === 'public'">
                <div class="mb-2">{{'select_unit' | translate}}</div>
                <kwot-custom-select
                  [formGroup]="rfqForm"
                  valueKey="value"
                  selectedValue="unit"
                  [selectOptions]="quantityInUnit"
                  placeholder="{{'choose_unit' | translate}}">
                </kwot-custom-select>
              </div>
            </div>

              <div class="col-md-6 mt-2">
                <div class="mb-2">{{ 'select_category' | translate }}</div>
                <kwot-custom-select
                  [formGroup]="rfqForm"
                  selectedValue="categoryName"
                  [selectOptions]="categories"
                  (optionSelected)="getVendorData($event)"
                  placeholder="{{'choose_category' | translate}}">
                </kwot-custom-select>
              </div>
            <ng-container *ngIf="rfqForm?.value?.refType === 'private'">
              <div class="col-md-6 mt-2">
                <div class="mb-2">{{ 'select_vendors' | translate }}</div>
                <kwot-custom-select
                  class="vendor-list-select"
                  [formGroup]="rfqForm"
                  selectedValue="vendorData"
                  [isMultiple]="true"
                  [selectOptions]="vendors"
                  (optionSelected)="selectVendors()"
                  placeholder="{{'choose_vendors' | translate}}">
                </kwot-custom-select>
                <ng-container *ngFor="let item of selectedVendorData; let i = index">
                  <div class="change-color d-flex align-items-center justify-content-between mt-3">
                    <h5 class="d-flex align-items-center fs-14 m-0">
                      {{item.name}}
                    </h5>
                    <div class="icons">
                      <div class="delete-icon" (click)="deleteVendor(i)">
                        <img src="assets/images/icons/Delete-icon.svg" class="img-fluid" alt="add icon"/>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <div class="col-md-12 form-group">
              <div class="mb-2">{{ 'description' | translate }}</div>
              <textarea class="form-control form-text-area resize-none" cols="20" rows="7"
                        placeholder="{{ 'type_here_your_message' | translate }}" formControlName="message">
              </textarea>
            </div>
            <div class="row">
              <div class="col-md-6 form-group" *ngIf="selectedValue === 'public'">
                <div class="mb-2">{{'shipping_terms' | translate}}</div>
                <kwot-custom-select
                  [formGroup]="rfqForm"
                  valueKey="value"
                  selectedValue="shippingTerms"
                  [selectOptions]="shippingTerms"
                  placeholder="{{'choose_shipping_terms' | translate}}">
                </kwot-custom-select>
              </div>

              <div class="col-md-6 form-group">
                <div class="mb-2">{{ 'payment_terms' | translate }}</div>
                <kwot-custom-select
                  [formGroup]="rfqForm"
                  valueKey="value"
                  selectedValue="paymentTerms"
                  [selectOptions]="paymentTerms"
                  placeholder="{{'choose_payment_terms' | translate}}">
                </kwot-custom-select>
              </div>
            </div>

            <div class="col-md-12 form-group" *ngIf="selectedValue === 'public'">
              <div class="mb-2">{{'documents' | translate}}</div>
              <div class="product-cover-img mb-2">
                <div class="d-flex flex-wrap align-items-center">
                  <div class="img-wrapper">
                    <img [lazyLoad]="documentFile" class="img-fluid document-img cursor-pointer" (click)="coverImage.click()"
                         defaultImage="assets/images/add-photo.svg" alt="">
                    <div class="delete-icon" *ngIf="documentFile">
                      <img class="img-fluid cursor-pointer" (click)="clearFile()"
                           src="assets/images/icons/delete.svg" alt="">
                    </div>
                    <input type="file" accept=".jpg, .gif, .png" class="d-none" #coverImage
                           (click)="coverImage.value = null" (change)="onImageSelected($event)">
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="rfqDynamicKey && rfqDynamicKey?.length > 0">
              <div class="col-md-12" *ngFor="let item of dynamicKeyArray.controls; let i = index"
                   formArrayName="dynamicKey">
                <div [formGroupName]="i">
                  <ng-container *ngIf="rfqDynamicKey[i].type === 'INPUT'">
                    <div class="form-group">
                        <span>
                          {{rfqDynamicKey[i].key | pascalCase}}
                          <span class="d-inline-block fs-16">
                            <ng-container *ngIf="rfqDynamicKey[i].isRequired">*</ng-container>
                            <ng-container *ngIf="!rfqDynamicKey[i].isRequired">({{'optional' | translate}})
                            </ng-container>
                          </span>
                        </span>
                      <input type="text" class="form-control" [formControlName]="rfqDynamicKey[i].key"
                             placeholder="{{'placeholder.type' | translate:{field: rfqDynamicKey[i].key | lowercase} }}">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="rfqDynamicKey[i].type === 'DROPDOWN'">
                    <div class="form-group">
                      <span>
                        {{rfqDynamicKey[i].key | pascalCase}}
                        <span class="d-inline-block fs-16">
                          <ng-container *ngIf="rfqDynamicKey[i].isRequired">*</ng-container>
                          <ng-container *ngIf="!rfqDynamicKey[i].isRequired">({{'optional' | translate}})
                          </ng-container>
                        </span>
                      </span>
                      <kwot-custom-select
                        [formGroup]="dynamicKeyArrayGroup(i)"
                        selectedValue="{{rfqDynamicKey[i].key}}"
                        [selectOptions]="(rfqDynamicKey[i]['list'] | mapForSelect)"
                        placeholder="{{'select_field' | translate:{field: rfqDynamicKey[i].key}  }}">
                      </kwot-custom-select>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="rfqDynamicKey[i].type === 'TEXTAREA'">
                    <div class="form-group">
                      <span>
                        {{rfqDynamicKey[i].key | pascalCase}}
                        <span class="d-inline-block fs-16">
                          <ng-container *ngIf="rfqDynamicKey[i].isRequired">*</ng-container>
                          <ng-container *ngIf="!rfqDynamicKey[i].isRequired">({{'optional' | translate}})
                          </ng-container>
                        </span>
                      </span>
                      <textarea class="form-control form-text-area" cols="20" rows="3"
                                [formControlName]="rfqDynamicKey[i].key"
                                placeholder="{{'placeholder.type' | translate:{field: rfqDynamicKey[i].key | lowercase} }}"></textarea>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <button type="button" class="btn-action py-3 mt-3"
                  [ngClass]="{'btn-action': rfqForm.valid, 'btn-disabled': rfqForm.invalid}"
                  [disabled]="rfqForm.invalid"
                  (click)="submit()">
            {{ isProduct ? ('send_offer_vendor' | translate) : ('invite_supplier_quote' | translate) }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
