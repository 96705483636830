import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {BuyerState, CheckGuestOrUpdate} from "@kwot/buyer-store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'kwot-add-product-review',
  templateUrl: './add-product-review.component.html',
  styleUrls: ['./add-product-review.component.scss']
})
export class AddProductReviewComponent implements OnInit, OnDestroy {

  public closeEvent: Subject<any>;
  unsubscriber = new Subject();
  @Input() productName: any;
  @Input() isSeller = false;
  @Input() currentUser: any;
  currentRating = 4;
  reviewMessage = '';
  email = '';
  firstName = '';
  lastName = '';
  errorMessage: any = {};
  ratingStars = [
    {
      value: 0,
      isSelected : true
    },
    {
      value: 1,
      isSelected : true
    },
    {
      value: 2,
      isSelected : true
    },
    {
      value: 3,
      isSelected : true
    },
    {
      value: 4,
      isSelected : true
    },
  ];
  images: any = [null];
  files: any = [null];

  constructor(
    public bsModalRef: BsModalRef,
    private buyerStore: Store<BuyerState>,

  ) {
  }

  ngOnInit(): void {
    if (this.currentUser) {
      this.email = this.currentUser.email
      this.firstName = this.currentUser.firstName
      this.lastName = this.currentUser.lastName
    }
    this.closeEvent = new Subject();
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
  checkFirstName() {
    this.callAPI()
    // if (this.firstName === '' ) {
    //   this.errorMessage.firstName = 'First name is required';
    //   return;
    // }  else {
    //   this.errorMessage.firstName = ''
    //   this.callAPI()
    // }
  }
  checkLastName() {
    this.callAPI()
    // if (this.lastName == '' ) {
    //   this.errorMessage.lastName = 'Last name is required';
    //   return;
    // } else {
    //   this.errorMessage.lastName = ''
    //   this.callAPI()
    // }
  }
  checkEmail() {
    if (this.email === '') {
      this.errorMessage.email = '';
      return;
    } else if (!new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(this.email)) {
      this.errorMessage.email = 'Enter valid email';
      return;
    } else {
      this.errorMessage.email = ''
      this.callAPI()
    }
  }
  checkReview() {
    if (this.reviewMessage === '') {
      this.errorMessage.reviewMessage = 'Review message is required';
      return;
    } else {
      this.errorMessage.reviewMessage = ''
    }
  }

  callAPI() {
      if (!this.currentUser) {
        // if (this.email && this.firstName && this.lastName) {
          this.buyerStore.dispatch(CheckGuestOrUpdate({
              body: {
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName
              },
              showLoader: false
            })
          );
        // }
      }
  }

  postReview() {
    if (this.reviewMessage) {
      this.closeEvent.next({
        rating: this.currentRating + 1,
        review: this.reviewMessage,
        images: this.files,
      });
      this.bsModalRef.hide();
    }
  }

  fillUpStar(index: any) {
    this.ratingStars.map((item) => {
      if (item.value <= index) {
        item.isSelected = true
      }
    })
  }

  fillUpBorderedStar() {
    this.ratingStars.map((item) => {
      if (item.value > this.currentRating) {
        item.isSelected = false
      }
    })
  }

  saveRatingValue(index : any) {
    this.currentRating = index
    this.ratingStars.map((item) => {
      item.isSelected = item.value <= index;
    })
  }

  async onImageSelected(event: any, index = 0) {
    const {target: {files}} = event;
    const file = files ? files[0] : undefined;
    if (file && file.type.includes('image')) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.images[index] = data;
      });
      this.files[index] = file;
    }
  }

  clearImage(index = 0) {
    if (index === 0 && this.images.length > 1) {
      this.images.splice(index, 1);
      this.files.splice(index, 1);
    } else {
      if (index === 0) {
        this.images[index] = '';
        this.files[index] = null;
      } else {
        this.images.splice(index, 1);
        this.files.splice(index, 1);
      }
    }
  }

}
