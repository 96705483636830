import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[noDecimal]'
})
export class NoDecimalDirective {

  constructor() {
  }

  @HostListener('keypress', ['$event']) onInputChange(event: any) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

}
