export const defaultFilters: any = {
  // price: {
  //   options: [
  //     {
  //       key: 'Low To High',
  //       value: 'low'
  //     },
  //     {
  //       key: 'High To Low',
  //       value: 'high'
  //     },
  //   ],
  //   selectedValue: null
  // },
  byRating: {
    options: [
      {
        key: 4,
        value: 4
      },
      {
        key: 3,
        value: 3
      },
      {
        key: 2,
        value: 2
      },
      {
        key: 1,
        value: 1
      },
    ],
    selectedValue: null
  }
}
