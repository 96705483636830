import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {measurementsList} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {select, Store} from "@ngrx/store";
import {MeasurementState} from "../+state/measurement.reducer";
import {ResetMeasurementState, SaveMeasurements} from "../+state/measurement.actions";
import {getUserMeasurements} from "../+state/measurement.selectors";
import {LocalstorageService} from "@kwot/app-config";

@Component({
  selector: 'kwot-custom-measurement',
  templateUrl: './custom-measurement.component.html',
  styleUrls: ['./custom-measurement.component.scss']
})
export class CustomMeasurementComponent implements OnInit, OnDestroy {
  measurementForm: UntypedFormGroup;
  metrics: any[] = [
    {name: "CENTIMETRE", id: "cm"},
    {name: "INCH", id: "inch"}
  ];
  _measurementsList = measurementsList;
  public closeEvent: Subject<any>;
  unsubscriber = new Subject();
  measurements: any
  currentUser: any
  orderId: any
  categoryMeasurements: any;
  measurementsDataList: any = []
  sizeMeArValues: any[] = [];
  selectedIds: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private measurementStore: Store<MeasurementState>,
    private ls: LocalstorageService
  ) {
    this.measurementStore.dispatch(ResetMeasurementState({params: {measurements: null}}));
  }

  subscribeToStore() {
    this.measurementStore.pipe(select(getUserMeasurements))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(measurements => {
        if (measurements) {
          this.measurementForm.patchValue({
            ...measurements
          })
          this.closeEvent.next({...measurements});
          this.bsModalRef.hide();
        }
      })
  }

  createForm() {
    const form: any = {};
    measurementsList.forEach((item: any) => {
      form[item.id] = ['']
    })
    this.measurementForm = this.formBuilder.group({
      metrics: ['cm', Validators.required],
      sizeMe: this.formBuilder.array([]),
    })
  }

  ngOnInit(): void {
    this.createForm();
    this.currentUser = this.ls.updateUserKey('get');
    this.closeEvent = new Subject();
    let user: any = {};

    if (this.currentUser) {
      this.subscribeToStore();
      user = this.currentUser;
    } else {
      user.measurements = this.measurements;
    }

    let measurements = this.categoryMeasurements.list.length > 0 ? this.categoryMeasurements.list.map((ms: any) => {
      return this._measurementsList.find(d => d.id === ms)
    }) : this._measurementsList;
    measurements.forEach((item: any, index: any) => {
      let hasValue = false;
      this.sizeMeArValues.push(this.formBuilder.group({
        id: [item.id, Validators.required],
        value: ['', Validators.required],
      }));
      if (user && user.measurements) {
        if (this.orderId) {
          if (this.measurements[item.id]) {
            hasValue = true;
            this.sizeMeArValues[index].value = this.measurements[item.id]
          }
        } else {
          if (user.measurements[item.id]) {
            hasValue = true;
            this.sizeMeArValues[index].value = user.measurements[item.id]
          }
        }
      }
      this.measurementsDataList.push({...item, disabled: hasValue})
    })

    this.measurementForm = this.formBuilder.group({
      metrics: [user?.measurements?.metrics || 'CM', Validators.required],
      sizeMe: this.formBuilder.array(this.sizeMeArValues),
    })
  }

  checkIfRequired(field: any) {
    return this.form[field].hasValidator(Validators.required)
  }

  get form() {
    return this.measurementForm.controls;
  }

  submitForm() {
    if (this.measurementForm.invalid) {
      return;
    }

    const measurements: any = {
      metrics: this.measurementForm.get('metrics').value,
    }
    this.measurementForm.value.sizeMe.map((value: any) => {
      measurements[value.id] = value ? value.value : ''
    })
    if (this.currentUser) {
      let body = {
        measurements: measurements,
        orderId: this.orderId
      }
      this.closeEvent.next({...measurements});
      this.measurementStore.dispatch(SaveMeasurements({body}))
    } else {
      let body = {
        measurements: measurements,
        orderId: this.orderId
      }
      this.measurementStore.dispatch(SaveMeasurements({body}))
      this.ls.updateMeasurementDetails('store', measurements);
      this.closeEvent.next({...measurements});
      this.bsModalRef.hide();
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

  updateSelections() {
    this.measurementsDataList = this.measurementsDataList.map((item: any) => {
      let val = this.measurementForm.controls['sizeMe'].value.find((d: any) => d.id === item.id);
      return {...item, disabled: !!val}
    })
  }

  selectedMeasurement(event: any, index: any) {
    this.measurementGroup.at(index).setValue({id: event, value: null})
    this.updateSelections();
  }

  addMeasurement() {
    (<UntypedFormArray>this.measurementForm.get('sizeMe')).push(this.formBuilder.group({
      id: ['', Validators.required],
      value: ['', Validators.required]
    }));
  }

  get measurementGroup() {
    return this.measurementForm.get('sizeMe') as UntypedFormArray;
  }

  deleteMeasurement(i: number) {
    const control = <UntypedFormArray>this.measurementForm.controls['sizeMe'];
    control.removeAt(i);
    const size = [...this.measurementForm.value.sizeMe];
    this.measurementGroup.patchValue(size);
    this.updateSelections();
  }
}
