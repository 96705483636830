<div class="measurement-details">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title fs-16 fw-bold text-center mb-0">{{ 'update_measurement' | translate }}</h3>
    <button type="button" class="btn-close m-0" (click)="bsModalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="account-content">
      <div class="step-header text-center">
        <div class="row">
          <div class="col-6">
            <div class="step-title step-border" [ngClass]="{'active': showStep1, 'complete': showStep2}">
              <h6 class="fs-20">1</h6>
              <span>
                {{ 'Open_camera' | translate }}
              </span>
            </div>
          </div>
          <div class="col-6">
            <div class="step-title" [ngClass]="{'active': showStep2}">
              <h6 class="fs-20"> 2 </h6>
              <span>
                {{ 'update' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="step-body">
        <div class="row">
          <div class="col-12">
            <ng-container *ngIf="showStep1">
              <div class="mb-4 mt-2">
                <p class="fs-16 text-center m-0">{{ 'iphone_android_open_camera_measurement' | translate }}</p>
              </div>
            </ng-container>
            <ng-container *ngIf="showStep2">
              <div class="mb-4 mt-2">
               ---
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="step-footer">
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-item-center justify-content-end">
              <button class="btn-action w-auto btn-green-border" (click)="step1()" *ngIf="showStep2">{{ 'back' | translate }}</button>
              <button type="submit" class="w-auto px-4 fs-14 fw-bold mx-2 btn-action btn" [ngClass]="{'btn-green-border': showStep1}" (click)="bsModalRef.hide()"
              aria-label="Close">{{showStep1 ? 'Cancel' : 'Done'}}</button>
              <button class="btn-action w-auto px-4" (click)="step2()" *ngIf="showStep1">{{ 'next' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
