import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomMeasurementComponent} from "./custom-mesurement/custom-measurement.component";
import {SharedModule} from "@kwot/shared";
import {ReactiveFormsModule} from "@angular/forms";
import {StoreModule} from "@ngrx/store";
import * as fromAuth from "./+state/measurement.reducer";
import {EffectsModule} from "@ngrx/effects";
import {MeasurementEffects} from "./+state/measurement.effects";
import {SelectModule} from "@kwot/select";

@NgModule({
  declarations: [
    CustomMeasurementComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SelectModule,
    ReactiveFormsModule,
    StoreModule.forFeature('measurements', fromAuth.reducer),
    EffectsModule.forFeature([MeasurementEffects]),
  ],
  exports: [CustomMeasurementComponent]
})
export class MeasurementModule {
}
