import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Subject, takeUntil} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AuthState, getLoggedInUser} from "@kwot/auth-store";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
  BuyerState,
  getCategories, getCurrencyData,
  getRfqDynamicKey,
  GetRfqDynamicKey, GetSellersList, getVendorsList, StartNegotiation
} from "@kwot/buyer-store";
import {UploadImageService, UtilService} from "@kwot/app-config";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {LoginModalComponent} from "../login-modal/login-modal.component";
// import {ShippingAddressComponent} from "../../../../../payment/src/lib/_components/shipping-address/shipping-address.component";

@Component({
  selector: 'kwot-rfq-modal',
  templateUrl: './rfq-modal.component.html',
  styleUrls: ['./rfq-modal.component.scss']
})
export class RfqModalComponent implements OnInit, OnDestroy {
  @Input() product: any;
  @Input() selectedPrice: any;
  @Input() discountPrice: any;
  @Input() changeQtyForNegotiate: any;
  @Input() modelType: any;
  @Input() selectedAddress: any;
  @Input() isProduct: any;
  @Input() isCopy: boolean = false;
  @Input() copyData: any;

  currentUser: any;
  unsubscriber = new Subject();
  public closeEvent: EventEmitter<any> = new EventEmitter();
  rfqForm: UntypedFormGroup;
  checkBoxData: any[] = [
    {name: "Private", value: "private"},
    {name: "Public", value: "public"}
  ];
  selectedValue = 'public'
  rfqDynamicKey: any = [];
  categories: any = [];
  vendors: any = [];
  currencyData: any;
  selectedVendorData: any[] = [];
  paymentTerms: any[] = [
    {name: "KWOT Escrow", value: "kwot"},
    {name: "Advanced Payment", value: "advance_payment"},
    {name: "Letter of Credit", value: "loc"},
    {name: "Payment after 30 Days", value: "after_30_days"},
    {name: "Open Account Terms", value: "open_account_terms"},
    {name: "Documentary Collection", value: "documentary_collection"},
    {name: "Consignment", value: "consignment"},
  ];
  shippingTerms: any[] = [
    {name: "Cost and Freight (CFR)", value: "cfr"},
    {name: "Cost, Insurance and Freight (CIF)", value: "cif"},
    {name: "Free on Board (FOB)", value: "fob"}
  ];
  quantityInUnit: any[] = [
    {name: "Kilo Gram", value: "kg"},
    {name: "Meters", value: "meters"},
    {name: "Numbers", value: "numbers"}
  ];
  file: any;
  documentFile: any;

  constructor(
    public bsModalRef: BsModalRef,
    private authStore: Store<AuthState>,
    private fb: UntypedFormBuilder,
    private buyerStore: Store<BuyerState>,
    private uploadImage: UploadImageService,
    private utilService: UtilService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private modelService: BsModalService,
  ) {

  }

  ngOnInit(): void {
    this.createForm();
    this.buyerStore.dispatch(GetRfqDynamicKey({item: this.selectedValue}));
    this.subscribeToStore();

    this.patchCopyRfq();
    if (!this.currentUser) {
      const modelRef: BsModalRef = this.modelService.show(LoginModalComponent, {
        keyboard: false,
        animated: true,
        ignoreBackdropClick: false,
        class: 'modal-md modal-dialog-centered custom-model',
        backdrop: 'static',
        initialState: {
          isB2BRfq: true
        }
      });
      modelRef.content.closeEvent.subscribe((result: any) => {
        if (result) {
          this.currentUser = result;
        }
      })
    } else {
      // const modelRef: BsModalRef = this.modelService.show(ShippingAddressComponent, {
      //   keyboard: true,
      //   animated: true,
      //   ignoreBackdropClick: false,
      //   class: 'modal-md modal-dialog-centered custom-model',
      //   backdrop: true,
      //   initialState: {
      //     isB2b: true
      //   }
      // });
      // modelRef.content.closeEvent.subscribe((result: any) => {
      //   this.selectedAddress = result;
      // })
    }
  }

  createForm() {
    this.rfqForm = this.fb.group({
      qty: [this.changeQtyForNegotiate || 20, Validators.required],
      message: ['', Validators.required],
      refType: [this.selectedValue, Validators.required],
      categoryName: [null, this.selectedValue === 'private' ? Validators.required : []],
      vendorData: [null, this.selectedValue === 'private' ? Validators.required : []],
      productName: [null, this.selectedValue === 'public' ? Validators.required : []],
      paymentTerms: [null, Validators.required],
      ...(this.rfqDynamicKey?.length > 0 ? {
        dynamicKey: this.fb.array([
          ...this.rfqDynamicKey.map((item: any) => {
            return this.fb.group({
              [item.key]: [item.type === 'DROPDOWN' ? null : '', item.isRequired ? Validators.required : []]
            })
          })
        ])
      } : {}),
      unit: [null, this.selectedValue === 'public' ? Validators.required : []],
      shippingTerms: [null, this.selectedValue === 'public' ? Validators.required : []],
      document: ['', this.selectedValue === 'public' ? Validators.required: []],
    })
  }

  async submit() {
    if (this.rfqForm.invalid) {
      return;
    }
    let dynamicKey: any = this.rfqDynamicKey.map((item: any, index: number) => {
      return {
        key: item.id || item._id,
        value: this.rfqForm.value.dynamicKey[index][item.key]
      }
    })
    let fromCurrency, toCurrency;
    if (this.product) {
      fromCurrency = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRateFrom(this.currencyData, this.product.currency || 'USD');
    }
    toCurrency = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRate(this.currencyData);
    let image;
    if (this.file) {
      let folderPath = 'buyer/';
      // this.loaderService.show();
      image = await this.uploadImage.uploadImageAndGetUrl({
        fileName: this.file?.name,
        contentType: this.file?.type,
        file: this.file,
        folderPath
      });
      // this.loaderService.hide();
    }
    let body: any = {
      userId: this.currentUser._id,
      quantity: this.rfqForm.value.qty,
      shippingId: this.selectedAddress?._id || this.copyData.shippingId,
      negotiationType: this.modelType || 'offer',
      dynamicKey,
      negotiationOfferType: this.product ? 'product' : this.selectedValue,
      selectedVendors: this.rfqForm.value.vendorData || [],
      ...(this.product ? {
        vendorId: this.product.vendorId._id,
        productId: this.product._id,
        variantId: this.product.selectedVariant,
        selectedOptions: this.product.selectedOptions || {},
        selectedPriceRange: this.selectedPrice,
        quantity: this.rfqForm.value.qty,
        price: this.rfqForm.value.qty * this.selectedPrice.price,
        totalPrice: this.selectedPrice.price * this.rfqForm.value.quantity,
        shippingCost: 0,
        platformFee: this.selectedPrice.platformFee,
        currencyData: this.currencyData,
        selectedVendors: [this.product.vendorId._id]
      } : {}),
      message: this.rfqForm.value.message,
      paymentTerms: this.rfqForm.value.paymentTerms,
      categoryName: this.rfqForm.value.categoryName,
      ...(this.selectedValue === 'public' ? {
        productName: this.rfqForm.value.productName,
        unit: this.rfqForm.value.unit,
        shippingTerms: this.rfqForm.value.shippingTerms,
        document: image ? image : this.documentFile,
      }: {}),
      currencyData: {
        ...(fromCurrency ? {
          from: {
            currency: this.product.currency || 'USD',
            rate: fromCurrency.rate
          },
        }: {} ),
        to: {
          currency: toCurrency.currencyCode,
          rate: toCurrency.rate
        }
      },
    }
    this.buyerStore.dispatch(StartNegotiation({body}));
    this.closeEvent.emit(this.rfqForm.value);
    this.bsModalRef.hide();
  }

  get dynamicKeyArray() {
    return this.rfqForm.get('dynamicKey') as UntypedFormArray;
  }

  dynamicKeyArrayGroup(index: number) {
    return this.dynamicKeyArray.controls[index] as UntypedFormGroup;
  }

  subscribeToStore() {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentUser = currentUser;
        }
      })
    this.buyerStore.pipe(select(getRfqDynamicKey))
      .subscribe(rfqDynamicKey => {
        if (rfqDynamicKey) {
          this.rfqDynamicKey = rfqDynamicKey;
          if (this.rfqDynamicKey.length > 0) {
            this.createForm();
            if (this.isCopy) {
              this.patchCopyRfq()
            }
          }
        }
      })
    this.buyerStore
      .pipe(select(getCategories))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((categories) => {
        if (categories) {
          categories.forEach((item: any) => {
            if (item.isRoot) {
              this.categories.push({
                name: item.name,
                id: item._id
              })
            }
          })
        }
      });
    this.buyerStore.pipe(select(getVendorsList))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(vendors => {
        if (vendors) {
          this.vendors = [...vendors.data];
          this.vendors.map((item: any) => {
            if (item.id) {
              this.vendors = this.vendors.filter((obj: any, index: any) => {
                return index === this.vendors.findIndex((o: any) => obj?.id === o?.id);
              });
            }
          })
        }
      })
    this.buyerStore.pipe(select(getCurrencyData))
      .subscribe(currencyData => {
        if (currencyData) {
          this.currencyData = currencyData;
        }
      })
  }

  getVendorData(event: any) {
    if (event) {
      this.rfqForm.patchValue({vendorData: null})
      this.buyerStore.dispatch(GetSellersList({
        params: {
          type: 'rfq',
          category: event.value
        }
      }))
    }
  }

  selectVendors() {
    this.selectedVendorData = this.vendors.filter((value: any) => this.rfqForm.value.vendorData.includes(value.id.toString()));
  }

  deleteVendor(index: number) {
    this.selectedVendorData.splice(index, 1);
    this.rfqForm.patchValue({
      vendorData: this.selectedVendorData.map(item => item.id)
    })
  }

  filterStatusValues(event: any) {
    this.selectedValue = event.target.value;
    this.rfqForm.patchValue({refType: event.target.value});
    this.buyerStore.dispatch(GetRfqDynamicKey({item: this.selectedValue}));
  }

  reduceQty() {
    if (this.rfqForm.value.qty <= 1) {
      return;
    }
    const qty = Number(this.rfqForm.value.qty) - 1;
    this.rfqForm.patchValue({qty: qty})
    this.updateValues();
  }

  increaseQty() {
    const qty = Number(this.rfqForm.value.qty) + 1;
    this.rfqForm.patchValue({qty: qty})
    this.updateValues();
  }

  updateValues() {
    if (this.discountPrice) {
      for (let i = 0; i < this.discountPrice.length; i++) {
        let item = this.discountPrice[i];
        if (item.isFixed) {
          if (this.rfqForm.value.qty >= Number(item.from) && this.rfqForm.value.qty <= Number(item.to)) {
            this.selectedPrice = item;
            break;
          }
        } else {
          if (Number(item.from) >= this.rfqForm.value.qty) {
            this.selectedPrice = item;
            break;
          }
        }
      }
    }
  }

  getImage(item: any, size: any) {
    return this.uploadImage.getImages(item.coverImage, size);
  }

  onImageSelected(event: any) {
    const {target: {files}} = event;
    const file = files ? files[0] : undefined;
    if (file) {
      const contentType = file.name.split('.').pop();
      if (!['jpg', 'gif', 'png', 'jpeg'].includes(contentType)) {
        this.toastr.warning(this.translateService.instant('only_allowed', {types: '.jpg, .gif or .png'}));
        return false;
      }
      if (this.getFileSize(file.size) > 4) {
        this.toastr.warning(this.translateService.instant('cannot_upload', {size: `4MB`}));
        return false;
      }
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.file = file;
        this.documentFile = data;
      });
      this.rfqForm.get('document').setValue(file);
      return true;
    }
    return false;
  }

  clearFile() {
    this.documentFile = '';
    this.rfqForm.get('document').setValue(null);
  }

  getFileSize(size: number) {
    return size / (1024 * 1024)
  }

  // Patch copy data values
  patchCopyRfq() {
    if (this.isCopy && this.copyData) {
      this.documentFile = this.copyData.document;
      this.rfqForm.patchValue({
        qty: this.copyData.quantity,
        productName: this.copyData.productName,
        message: this.copyData.message,
        refType: this.copyData.negotiationOfferType,
        paymentTerms: this.copyData.paymentTerms,
        shippingTerms: this.copyData.shippingTerms,
        unit: this.copyData.unit,
        document: this.copyData.document,
        categoryName: this.categories.find((item: any) => item.id === this.copyData.categoryName)?.id,
        ...(this.copyData?.dynamicKey.length > 0 ? {
          dynamicKey: [
            ...this.rfqDynamicKey.map((item: any) => {
              if (item.key) {
                let value = this.copyData.dynamicKey.find((c: any) => {
                  if (typeof c.key === 'string') {
                    return (c.key === item.id || c.key === item._id)
                  } else {
                    return (c.key && c.key._id === item._id)
                  }
                });
                return {
                  [item.key]: item.type === 'DROPDOWN' ? (value ? (value.value || null) : null) : (value ? (value.value || '') : '')
                }
              }
              return null;
            }).filter((t: any) => t)
          ]
        } : {})
      })
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
}
