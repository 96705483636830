<swiper [slidesPerView]="1" [spaceBetween]="10"
        [pagination]="{ clickable: true }">
  <ng-container *ngIf="product">
    <ng-template swiperSlide>
      <img [lazyLoad]="getImage(product, 'medium')"
           defaultImage="assets/images/loaders/skeleton_loader.gif"
           (click)="type === 'ads' ? redirect(product) : routeClick(product.slug)" alt="">
    </ng-template>
    <ng-container *ngIf="product?.extraImages?.length > 0">
      <ng-container *ngFor="let image of product?.extraImages">
        <ng-template swiperSlide>
          <img defaultImage="assets/images/loaders/skeleton_loader.gif"
               (click)="type === 'ads' ? redirect(product) :routeClick(product.slug)"
               [lazyLoad]="getExtraImage(image, 'medium')"
               alt="">
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!product;">
    <ng-template swiperSlide><img src="assets/images/recent.jpg" alt=""></ng-template>
  </ng-container>
</swiper>
