import {Action, createReducer, on} from "@ngrx/store";
import {
  ResetMeasurementState,
  SaveMeasurements,
  SaveMeasurementsError,
  SaveMeasurementsSuccess,
} from "./measurement.actions";

export interface MeasurementState {
  success: string;
  error: string;
  measurements: any;
}

const initMeasurementState: MeasurementState = {
  success: '', error: '',
  measurements: null
}

const measurementReducer = createReducer(initMeasurementState,
  on(SaveMeasurements, (state) => ({...state, error: '', success: ''})),
  on(SaveMeasurementsSuccess, (state, {measurements}) => ({...state, error: '', success: '---IGNORE---', measurements})),
  on(SaveMeasurementsError, (state, {error}) => ({...state, error, success: ''})),

  on(ResetMeasurementState, (state, {params}) => ({
    ...state,
    ...params
  }))
)

export function reducer(state = initMeasurementState, action: Action) {
  return measurementReducer(state, action);
}
