export const environment = {
  production: false,
  apiUrl: 'https://yt40strhwi.execute-api.us-east-1.amazonaws.com/dev/v1',
  google_key: '341078272776-31vppl0h663ol2ecq0b0jlk608qqhl0j.apps.googleusercontent.com',
  google_recaptcha_site_key: '6LdZ6zkeAAAAADsF4kZjyjNhR7jnfiTGzrPEhlnG',
  google_recaptcha_secret: '6LdZ6zkeAAAAAJnO4XD-th0HYQFtYKTjx18yEP4d',
  type: 'user',
  stripe_publishable_key: 'pk_test_51KJyV0BMHzOCaZLFfOrrgwd2eWIwlqUAaGBaYNuddUrk9i9Al6OZF171qKUXefimQgwP7brLoKYhKXbPcSjkkNWv00o1YeI3PG',
  flutterwave_public_key: 'FLWPUBK_TEST-1c28017fc812e2a4fe375a7efd7117be-X',
  interSwitchUrl: 'https://hostedifelds.qa.interswitchng.com/sdk.js',
  interSwitchMerchantCode: 'MX82084',
  interSwitchPaymentId: 'Default_Payable_MX82084',
  interSwitchPaymentConfirmUrl: 'https://qa.interswitchng.com/collections/api/v1/gettransaction.json',
  // becomeAVendorUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSeHBrNtdCxoA6vl82S3f1pLwZ5jyZ61DEcP3JMvOp-XBPVc-A/viewform?pli=1&pli=1&edit2=2_ABaOnufxUYdgZ3jjZa7OBdkFq56X3or_gfoIkogE_snjB97LUya0N0s9NkGhO7vfNA',
  becomeAVendorUrl: 'https://b2bvendor-dev.kwot.com/vendor/register',
  ip_api :'https://api.ipgeolocation.io/ipgeo?apiKey=386b26cb610a499aa8e7ab71e07dbf71',
  gaTag: '',
  applicationType: 'b2b',
  s3Bucket: 'kwot.api',
  sizerApiUrl: 'https://webscan.sizer.me/sizer.loader.js',
  sizerClientId: 'KwotLimited.CLYrKlg6eZ',
  websocket: 'wss://6ljbqkqxd6.execute-api.us-east-1.amazonaws.com/dev',
  webUrl: 'https://buyer-dev.kwot.com',
  alatPayUrl: 'https://alatpay-client.azurewebsites.net/js/alatpay.js',
  alatPayBusinessId: 'cca1e068-2220-49a9-4181-08dafc0c53a0',
  alatPayApiKey: '4401dcd8e06c4c768d2e30619767e86b',
  barclycardURL: "https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp",
  language: "en_US",
  pspId: 'epdq1683941',
  barclySecret: "9@8218ge3jkmw3hbo"
};
