import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageCropperComponent} from './image-cropper/image-cropper.component';
import {ImageCropperModule as CropperModule} from "ngx-image-cropper";
import {SharedModule} from "@kwot/shared";


@NgModule({
  declarations: [
    ImageCropperComponent
  ],
  imports: [
    CommonModule,
    CropperModule,
    SharedModule
  ],
  exports: [ImageCropperComponent]
})
export class ImageCropperModule {
}
