import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {User, generateCategoryTree, currenciesWithSymbol} from '@kwot/data-models';
import {select, Store} from '@ngrx/store';
import {BuyerState, ResetBuyerState} from '@kwot/buyer-store';
import {
  GetBuyerSuccess,
  GetCartItem,
  getCategories,
  getNotifications, getPolicyData,
  GetWishListDetials
} from '@kwot/buyer-store';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject, takeUntil} from 'rxjs';
import {SearchComponent} from '../../../../../buyer-shared/src/lib/modules/home/components/search/search.component';
import {AuthState, LogoutUser} from "@kwot/auth-store";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {NavigationEnd, Router} from "@angular/router";
import {
  AcceptPolicyDetail,
  GetCategories,
  GetNotifications, GetPolicyDetail,
  ReadNotification,
} from '@kwot/buyer-store'
import {Title} from "@angular/platform-browser";
import {WindowService} from "@kwot/app-config";
import {getWishlist} from "../../../../../buyer-shared/src/lib/utils/wishlistManagement";
import {DOCUMENT} from "@angular/common";
import {ConfirmPolicyComponent} from "../../../../../vendor-buyer-shared/src/lib/components/confirm-policy/confirm-policy.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'kwot-buyer-header',
  templateUrl: './buyer-header.component.html',
  styleUrls: ['./buyer-header.component.scss'],
})
export class BuyerHeaderComponent implements OnInit, AfterViewInit {
  @Input() currentUser: User;
  @Input() isWhite = false;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  bsModalRef: BsModalRef;
  unsubscriber = new Subject();
  cartQuantity: number;
  wishList: any = [];
  categories: any[] = [];
  notifications: any[] = [];
  totalNotifications = 0;
  viewNotification: any = null;
  isReadForNotification = false;
  currentPage = -1;
  isMobile: boolean;
  screenSize: number;
  categoryValue: any = '';
  productLength = 0;
  public text: String;
  searchInput: string;
  showHeader: boolean;
  isShowNavbar: boolean = false;
  isOrderSuccess: boolean = false;
  languages: any[] = [
    {id: "en", name: "English"},
    {id: "es", name: "Spanish"},
    {id: "fr", name: "French"},
    {id: "zh", name: "Mandarin"},
    {id: "yo", name: "Yoruba"},
    {id: "ig", name: "Igbo"},
    {id: "ha", name: "Hausa"},
    {id: "pe", name: "Pidgin"}
  ];
  languagesShortList: any[] = [
    {id: "en", name: "En"},
    // {id: "es", name: "Es"},
    {id: "fr", name: "Fr"},
    {id: "zh", name: "Ma"},
    {id: "yo", name: "Yo"},
    {id: "ig", name: "Ig"},
    {id: "ha", name: "Ha"},
    {id: "pe", name: "Pe"}
  ];
  selectedLanguage: any = this.translationService.getDefaultLang();
  currencyList: any[] = [
    {id: "USD", name: "USD"},
    {id: "GBP", name: "GBP"},
    {id: "EUR", name: "EUR"},
    {id: "NGN", name: "NGN"},
    {id: "ZAR", name: "ZAR"},
    {id: "BWP", name: "BWP"},
    {id: "KES", name: "KES"},
    {id: "RWF", name: "RWF"},
    {id: "XOF", name: "XOF"},
    {id: "MAD", name: "MAD"},
    {id: "EGP", name: "EGP"},
    {id: "CNY", name: "CNY"},
  ];
  currencySymbolList: any[] = [
    {id: "USD", name: '$'},
    {id: "GBP", name: '£'},
    {id: "EUR", name: '€'},
    {id: "NGN", name: '₦'},
    {id: "ZAR", name: 'R'},
    {id: "BWP", name: 'P'},
    {id: "KES", name: 'KSh'},
    {id: "RWF", name: 'R₣'},
    {id: "EGP", name: 'E£'},
    {id: "XOF", name: 'CFA'},
    {id: "MAD", name: 'DH'},
    {id: "CNY", name: 'Ұ'}
  ];
  selectedCurrency: any = 'USD';

  constructor(
    private modalService: BsModalService,
    private buyerStore: Store<BuyerState>,
    private localStorageService: LocalstorageService,
    private authStore: Store<AuthState>,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: any,
    private titleService: Title,
    private windowService: WindowService,
    @Inject(DOCUMENT) private document: Document,
    private translationService: TranslateService
  ) {
    this.buyerStore.dispatch(ResetBuyerState({params: {cart: null}}));
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 991;
    })
    this.document.addEventListener('click', () => {
      let doc = this.document.getElementById('nav-bar-button-toggler');
      if (doc) {
        let navBar = this.document.getElementById('navbarNav');
        if (navBar && navBar.classList.contains('show')) {
          doc.click();
        }
      }
    })
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url.split('/').filter((t) => t);
        if (url.length >= 0) {
          url = event.urlAfterRedirects.split('/').filter((t) => t);
        }
        if (url[1].includes('?')) {
          const saperateUrl = url[1].split('?');
          url[1] = saperateUrl[0];
        }
        this.showHeader = url[1] === 'checkout' || url[1] === 'review-checkout' || url[1] === 'order-success' || url[1] === 'category-selection' || url[1] === 'profiles';
        this.isOrderSuccess = url[1] === 'order-success';
        // let currentRoute = '';
        // if (url[1]) {
        //   currentRoute = url[1];
        // } else {
        //   currentRoute = '';
        // }
        // this.validatePolicy(currentRoute);
      }
    });
    const url = this.router.url.split('/').filter((t) => t);
    this.showHeader = url[1] === 'checkout' || url[1] === 'review-checkout' || url[1] === 'order-success' || url[1] === 'category-selection' || url[1] === 'profiles';
    this.isOrderSuccess = url[1] === 'order-success';
    if (this.localStorageService.updateSelectedCurrency('get')) {
      this.selectedCurrency = this.localStorageService.updateSelectedCurrency('get').location.currencyCode;
    } else {
      this.selectedCurrency = 'USD';
    }
  }

  ngOnInit(): void {
    this.subscribeToStore();
    // this.buyerStore.dispatch(GetCart());
    this.buyerStore.dispatch(GetCategories());
    if (this.currentUser && !this.currentUser.isDeleted) {
      this.loadNotifications();
    }

    this.titleService.setTitle('Kwot - Amazing Africa. Now, delivered at your doorstep');
    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 991;
  }

  validatePolicy(currentRoute: string) {
    if (currentRoute === 'review-checkout') {
      let policyToCheck: any[] = ['shippingPolicy', 'returnPolicy', 'buyerTerms', 'buyerPolicy', 'cookiePolicy'];
      this.buyerStore.dispatch(GetPolicyDetail({params: {policies: JSON.stringify(policyToCheck)}}))
    }
  }

  ngAfterViewInit() {
    let doc = document.getElementById('account-menu');
    if (doc) {
      doc.addEventListener('click', function (e) {
        e.stopPropagation();
      })
    }
  }

  navigate(index: any) {
    this.categories[index].isSelected = !this.categories[index].isSelected
  }

  getChildren(category: any, title: any) {
    let result;
    if (title === '') {
      const update = (category: any, title: any) => category.map(
        (o: any) => o.isSelected
          ? {...o, isSelected: !o.isSelected}
          : o.hasOwnProperty('children') && o.children.length > 0 ? {...o, children: update(o.children, title)} : o
      );
      result = update(category, title);
    } else {
      const update = (category: any, title: any) => category.map(
        (o: any) => o.text === title
          ? {...o, isSelected: !o.isSelected}
          : o.hasOwnProperty('children') && o.children.length > 0 ? {...o, children: update(o.children, title)} : o
      );
      result = update(category, title);
    }
    this.categories = result;
  }


  search(event: any) {
    if (this.isMobile) {
      this.bsModalRef = this.modalService.show(SearchComponent, {initialState: {searchInput: this.searchInput}});
      this.bsModalRef.setClass('full-screen-modal');
    } else if (event.keyCode === 13) {
      this.bsModalRef = this.modalService.show(SearchComponent, {initialState: {searchInput: this.searchInput}});
      this.bsModalRef.setClass('full-screen-modal');
      this.searchInput = '';
    }
  }

  subscribeToStore() {
    this.buyerStore
      .pipe(select(GetCartItem))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((cartItem) => {
        if (cartItem) {
          if (cartItem.items) {
            this.cartQuantity = cartItem.items.reduce(function (totalQty: number, qty: any) {
              return totalQty + qty.quantity;
            }, 0);
          }
        } else {
          this.cartQuantity = 0;
        }
      });

    this.buyerStore
      .pipe(select(GetWishListDetials))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((wishList) => {
        // let count = 0;
        this.productLength = 0;
        if (wishList && this.currentUser) {
          this.wishList = JSON.parse(JSON.stringify(wishList)) || [];
        } else {
          this.wishList = getWishlist();
        }
        this.wishList.forEach((item: any) => {
          // count += item.productId?.length
        })
        this.productLength = this.wishList.length;
      });

    this.buyerStore
      .pipe(select(getCategories))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((categories) => {
        if (categories) {
          this.categories = generateCategoryTree(categories, '', true, false);
          // this.categories.sort((a: any, b: any) => a.order - b.order)
        }
      });
    this.buyerStore
      .pipe(select(getNotifications))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((notifications) => {
        if (notifications) {
          this.totalNotifications = notifications?.count;
          this.notifications = [...this.notifications, ...notifications?.notiData];
        }
      });
    this.buyerStore
      .pipe(select(GetBuyerSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((success) => {
        if (success && success === '---IGNORE---') {
          if (this.isReadForNotification) {
            this.isReadForNotification = false;
            let noti = this.notifications.findIndex(t => t._id === this.viewNotification._id);
            if (noti !== -1) {
              this.notifications[noti] = {
                ...this.notifications[noti],
                isRead: true
              }
            }
          }
        }
      });

    this.buyerStore.pipe(select(getPolicyData))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(policy => {
        if (policy) {
          let policyToVerify: any = [];
          policy.policies.forEach((item: any) => {
            if (policy.user[item.terms] !== item.version) {
              policyToVerify.push(item);
            }
          })

          this.verifyPolicies(policyToVerify);
        }
      })
  }

  verifyPolicies(policies: any) {
    if (policies.length > 0) {
      const modelRef: BsModalRef = this.modalService.show(ConfirmPolicyComponent, {
        keyboard: false,
        animated: true,
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered',
        initialState: {
          policies: policies
        }
      });
      modelRef.content.closeEvent.subscribe((result: any) => {
        this.buyerStore.dispatch(AcceptPolicyDetail({params: {policies: JSON.stringify(policies.map((t: any) => t.terms))}}));
      });
    }
  }

  showNotification(notification: any) {
    this.viewNotification = notification;
    if (!notification.isRead) {
      this.isReadForNotification = true;
      this.buyerStore.dispatch(ReadNotification({notificationId: notification._id}));
    }
  }

  getText(title: string) {
    if (title) {
      if (title === 'Thanks for your order!' || title === 'Product Delivered') {
        return 'My Order';
      }
      if (title === 'Good news!') {
        return 'Track my shipment';
      }
      if (title === 'Product return request' || title === 'Return request update') {
        return 'My Return Requests';
      }
    }
    return 'Click here to know more'
  }

  loadNotifications() {
    this.currentPage = this.currentPage + 1;
    this.buyerStore.dispatch(GetNotifications({
      params: {page: this.currentPage, limit: 2}
    }))
  }

  logoutUser() {
    this.localStorageService.clearAllLocalStorage();
    this.authStore.dispatch(LogoutUser());
    this.router.navigate([`/user/login`]);
  }

  showNavbar(event: boolean) {
    // this.isShowNavbar = event;
    this.router.navigate([`/user/category-selection`]);
  }

  changeLanguage(value: any) {
    this.selectedLanguage = value.value;
    localStorage.setItem('language', this.selectedLanguage);
    this.translationService.use(this.selectedLanguage);
    setTimeout(() => {
      window.location.reload();
    }, 100)
  }

  changeCurrency(value: any) {
    this.selectedCurrency = value.value;
    const data = currenciesWithSymbol.find((item: any) => item.name === this.selectedCurrency)
    const currencyData = {
      location: {
        countryCode: this.localStorageService.updateSelectedCurrency('get').location.countryCode,
        countryName: this.localStorageService.updateSelectedCurrency('get').location.countryName,
        currencyCode: data.name,
        currencySymbol: data.symbol
      },
      rates: this.localStorageService.updateSelectedCurrency('get').rates
    }
    this.localStorageService.updateSelectedCurrency('store', currencyData);
    setTimeout(() => {
      window.location.reload();
    }, 100)
  }
}
