import {LocalstorageService} from '@kwot/app-config';
import {Component, OnInit, Input, ElementRef, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject, takeUntil} from 'rxjs';
import {UploadImageService} from "@kwot/app-config";
import {
  BuyerState,
  CreateAdsTransaction,
  getSearchProductList,
  GetSearchProducts,
  GetSearchProductsSuccess
} from "@kwot/buyer-store";

@Component({
  selector: 'kwot-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  panelOpenState = false;
  @Input() productDetail?: any;
  public modalRef: BsModalRef;
  unsubscriber = new Subject();
  @ViewChild('search') searchElement: ElementRef;
  searchList: any[] = [];
  searchInput: any;
  category: any[] = [];
  product: any[] = [];
  seller: any[] = [];
  featuredProductsForSearched: any[] = [];
  relatedProductsForSearched: any[] = [];
  relatedProductsFromSiblingCategory: any[] = [];
  recentlyViewed: any[] = [];
  recentProducts: any = [];
  recentlySearch: any[] = [];
  ads: any[] = [];
  timeout: any;
  isApiWorking: boolean = false;

  constructor(
    public modalService: BsModalService,
    private localstorageService: LocalstorageService,
    private uploadImage: UploadImageService,
    private buyerStore: Store<BuyerState>
  ) {
  }

  ngOnInit(): void {
    this.subscribeToStore();
    if (this.localstorageService.updateRecentSearch('get')) {
      this.recentlySearch = this.localstorageService.updateRecentSearch('get');
    }

    if (this.searchInput) {
      this.search(this.searchInput);
    }
  }

  subscribeToStore() {
    this.buyerStore
      .pipe(select(getSearchProductList))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((searchResult) => {
        if (searchResult) {
          this.isApiWorking = false;
          this.product = searchResult.product;
          this.category = searchResult.category;
          this.seller = searchResult.category;
          this.ads = [...searchResult.adsToShow];

          this.featuredProductsForSearched = searchResult.featuredProductsForSearched;
          this.relatedProductsForSearched = searchResult.relatedProductsForSearched;
          this.relatedProductsFromSiblingCategory = searchResult.relatedProductsFromSiblingCategory;
        }

        if (this.product && this.product.length > 0) {
          const products = this.product
          if (this.recentlySearch) {
            let itemExists;
            for (let i = 0; i < products.length; i++) {
              itemExists = this.recentlySearch.find((item) => item._id === products[i]._id)
              if (!itemExists) {
                this.recentlySearch.push(products[i]);
              }
            }
          }
          this.localstorageService.updateRecentSearch('store', this.recentlySearch);
        }
      });
  }

  search(event: any) {
    this.isApiWorking = true;
    if (event) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.buyerStore.dispatch(GetSearchProducts({item: event}));
      }, 1000);
    }
  }

  getImage(item: any, size: any) {
    return this.uploadImage.getImages(item, size);
  }

  redirect(item: any) {
    this.buyerStore.dispatch(CreateAdsTransaction({
      body: {
        adsId: [item._id],
        type: 'click',
        ...(item.vendorId ? {productId: item.productId} : {})
      },
    }))
    if (!item.vendorId) {
      window.open('https://' + item.websiteUrl.replace('https://', '').replace('http://', ''), '_blank');
    }
    if (item.vendorId) {
      window.open(`user/product/${item.productSlug}?sponsored=${item._id}`, '_blank');
    }
  }

  closeModal() {
    this.modalService.hide();
    this.buyerStore.dispatch(GetSearchProductsSuccess({searchData: ''}));
  }
}
