<div class="app-header-white"></div>
<div class="py-lg-3 pt-3 pb-4 report-on">
  <div class="container-fluid">
    <div class="d-flex align-items-center mb-3">
      <span class="cursor-pointer mx-2 search" (click)="closeModal()">
        <img src="assets/images/icons/back.svg" alt=""/>
      </span>
      <button class="btn btn-search-icon-set">
        <img
          src="assets/images/icons/gray-search-icons.svg"
          class="search"
          alt=""
        />
      </button>
      <div class="search-section w-100">
        <form class="position-relative">
          <input
            autofocus
            class="form-control px-0"
            placeholder="{{ 'search_placeholder' | translate }}"
            name="searchInput"
            [(ngModel)]="searchInput"
            (ngModelChange)="search($event)"
            autocomplete="off"
          />
        </form>
      </div>
      <span class="cursor-pointer mx-2" (click)="closeModal()">
        <img class="img-fluid" src="assets/images/icons/Cross.svg" alt=""/>
      </span>
    </div>
  </div>
</div>
<div
  class="row mb-32"
  *ngIf="
    !isApiWorking &&
    searchInput &&
    category?.length === 0 &&
    product?.length === 0 &&
    seller?.length === 0
  "
>
  <div class="empty-cart">
    <div class="d-flex">
      <div class="cart-detail container">
        <p class="fw-bold empty-card-content">
          {{ 'we_not_have' | translate }} "{{ searchInput }}".{{ 'search_item_note' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="main-content pb-72 mt-lg-5">
  <div class="container">
    <div class="product mb-3" *ngIf="product?.length > 0 || ads.length > 0">
      <div class="col-12 pt-2">
        <h1 class="fs-32 mb-3 fs-xs-24 brand-black title-cart font-weight-bold">
          {{ 'product' | translate }}
        </h1>
      </div>
      <div class="row row--m-5">
        <div  *ngIf="ads.length">
          <ng-container *ngFor="let item of ads">
            <div class="col-lg-3 col-md-6 col-6 col--p-5 mb-56 mb-xs-22 position-relative">
              <div class="cat-box position-relative">
                <div class="badge bg-dark sponsored" containerClass="custom-tooltip"
                     tooltip="{{ 'product_search_tooltip' | translate }}"
                     placement="right">
                  {{ 'sponsored' | translate }}
                </div>
                <div class="app-card-two">
                  <kwot-card-two type="ads" (click)="closeModal()" [product]="item"></kwot-card-two>
                </div>
              </div>
              <div class="text-center mt-3">
                <a (click)="redirect(product); closeModal()" title="{{ item?.campaignName }}"
                   class="neutral_40 fs-18 font-weight-normal product_name" href="#">
                  {{ item?.campaignName }}
                </a>
                <kwot-product-price [price]="item" tag="span" class="d-flex align-items-center justify-content-center product-price-space mt-1 brand-black fs-16 font-weight-bold" type="product" field="price" [showDiscount]="true" [platformFee]="true"></kwot-product-price>
                <div class="col-12">
                  <div class="app-product-reviews mt-2 d-flex justify-content-center">
                    <kwot-product-reviews
                      [productId]="item?._id"
                      [productName]="item?.productName"
                      [productRating]="item?.rating || 0"
                      [productReviewCount]="item.ratingCount || 0"
                      [showRating]="true"
                    ></kwot-product-reviews>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
        <ng-container *ngFor="let item of product | slice: 0:4">
          <div class="col-lg-3 col-md-6 col-6 col--p-5 mb-56 mb-xs-22 position-relative">
            <div class="cat-box position-relative">
              <kwot-dynamic-wishlist [product]="item"></kwot-dynamic-wishlist>
              <div class="app-card-two">
                <kwot-card-two
                  (click)="closeModal()"
                  [product]="item"
                ></kwot-card-two>
              </div>
            </div>
            <div class="text-center mt-3">
              <a
                [routerLink]="['/user/product', item.slug]"
                (click)="closeModal()"
                class="neutral_40 fs-18 font-weight-normal product_name" title="{{ item?.productName }}"
                href="#"
              >
                {{ item?.productName }}
              </a>
              <kwot-product-price [price]="item" tag="span" class="d-flex align-items-center justify-content-center product-price-space mt-1 brand-black fs-16 font-weight-bold" type="product" field="price" [showDiscount]="true" [platformFee]="true"></kwot-product-price>
              <div class="col-12">
                <div class="app-product-reviews mt-2 d-flex justify-content-center">
                  <kwot-product-reviews
                    [productId]="item?._id"
                    [productName]="item?.productName"
                    [productRating]="item?.rating || 0"
                    [productReviewCount]="item.ratingCount || 0"
                    [showRating]="true"
                  ></kwot-product-reviews>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="product mb-3" *ngIf="relatedProductsForSearched?.length > 0">
      <div class="col-12 pt-2">
        <h1 class="fs-32 mb-3 fs-xs-24 brand-black title-cart font-weight-bold">
          {{ 'you_might_also_like' | translate }}
        </h1>
      </div>
      <div class="row row--m-5">
        <ng-container *ngFor="let item of relatedProductsForSearched">
          <div class="col-lg-3 col-md-6 col-6 col--p-5 mb-56 mb-xs-22 position-relative">
            <div class="cat-box position-relative">
              <div class="app-card-two">
                <kwot-card-two
                  (click)="closeModal()"
                  [product]="item"
                ></kwot-card-two>
              </div>
            </div>
            <div class="text-center mt-3">
              <a
                [routerLink]="['/user/product', item.slug]"
                (click)="closeModal()"
                class="neutral_40 fs-18 font-weight-normal product_name" title="{{ item?.productName }}"
              >{{ item?.productName }}</a
              >
              <kwot-product-price [price]="item" tag="span" class="d-flex align-items-center justify-content-center product-price-space mt-1 brand-black fs-16 font-weight-bold" type="product" field="price" [showDiscount]="true" [platformFee]="true"></kwot-product-price>
              <div class="col-12">
                <div class="app-product-reviews mt-2 d-flex justify-content-center">
                  <kwot-product-reviews
                    [productId]="item?._id"
                    [productName]="item?.productName"
                    [productRating]="item?.rating || 0"
                    [productReviewCount]="item.ratingCount || 0"
                    [showRating]="true"
                  ></kwot-product-reviews>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="product mb-3"
      *ngIf="relatedProductsFromSiblingCategory?.length > 0"
    >
      <div class="col-12 pt-2">
        <h1 class="fs-32 mb-3 fs-xs-24 brand-black title-cart font-weight-bold">
          {{ 'You_want_check' | translate }}
        </h1>
      </div>
      <div class="row row--m-5">
        <ng-container *ngFor="let item of relatedProductsFromSiblingCategory">
          <div class="col-lg-3 col-md-6 col-6 col--p-5 mb-56 mb-xs-22 position-relative">
            <div class="cat-box position-relative">
              <div class="app-card-two">
                <kwot-card-two
                  (click)="closeModal()"
                  [product]="item"
                ></kwot-card-two>
              </div>
            </div>
            <div class="text-center mt-3">
              <a
                [routerLink]="['/user/product', item.slug]"
                (click)="closeModal()"
                class="neutral_40 fs-18 font-weight-normal product_name" title="{{ item?.productName }}"
              >{{ item?.productName }}</a>
              <kwot-product-price [price]="item" tag="span" class="d-flex align-items-center justify-content-center product-price-space mt-1 brand-black fs-16 font-weight-bold" type="product" field="price" [showDiscount]="true" [platformFee]="true"></kwot-product-price>
              <div class="col-12">
                <div class="app-product-reviews mt-2 d-flex justify-content-center">
                  <kwot-product-reviews
                    [productId]="item?._id"
                    [productName]="item?.productName"
                    [productRating]="item?.rating || 0"
                    [productReviewCount]="item.ratingCount || 0"
                    [showRating]="true"
                  ></kwot-product-reviews>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="product mb-3" *ngIf="featuredProductsForSearched?.length > 0">
      <div class="col-12 pt-2">
        <h1 class="fs-32 mb-3 fs-xs-24 brand-black title-cart font-weight-bold">
          {{ 'featured_items_like' | translate }}
        </h1>
      </div>
      <div class="row row--m-5">
        <ng-container *ngFor="let item of featuredProductsForSearched">
          <div class="col-lg-3 col-md-6 col-6 col--p-5 mb-56 mb-xs-22 position-relative">
            <div class="cat-box position-relative">
              <div class="app-card-two">
                <kwot-card-two
                  (click)="closeModal()"
                  [product]="item"
                ></kwot-card-two>
              </div>
            </div>
            <div class="text-center mt-3">
              <a
                [routerLink]="['/user/product', item.slug]"
                (click)="closeModal()"
                class="neutral_40 fs-18 font-weight-normal product_name" title="{{ item?.productName }}"
              >{{ item?.productName }}</a>
              <kwot-product-price [price]="item" tag="span" class="d-flex align-items-center justify-content-center product-price-space mt-1 brand-black fs-16 font-weight-bold" type="product" field="price" [showDiscount]="true" [platformFee]="true"></kwot-product-price>
              <div class="col-12">
                <div class="app-product-reviews mt-2 d-flex justify-content-center">
                  <kwot-product-reviews
                    [productId]="item?._id"
                    [productName]="item?.productName"
                    [productRating]="item?.rating || 0"
                    [productReviewCount]="item.ratingCount || 0"
                    [showRating]="true"
                  ></kwot-product-reviews>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="categoty mb-56" *ngIf="category?.length > 0">
      <div class="col-12 pt-2">
        <h1 class="fs-32 mb-3 fs-xs-24 brand-black title-cart font-weight-bold">
          {{ 'category' | translate }}
        </h1>
      </div>
      <div class="row row--m-5">
        <ng-container *ngFor="let item of category | slice: 0:4">
          <div class="col-lg-3 col-md-6 col-5 col-cus-space">
            <div class="cat-box">
              <div class="card-one">
                <div
                  class="img-cat-box position-relative"
                  (click)="closeModal()"
                  [routerLink]="['/user/category', item?.slug]"
                >
                  <img
                    [lazyLoad]="getImage(item.icon, 'medium')"
                    defaultImage="assets/images/loaders/skeleton_loader.gif"
                    alt=""
                  />
                  <a class="btn btn-white">{{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="product mb-3" *ngIf="recentlySearch?.length > 0">
      <div class="col-12 pt-2">
        <h1 class="fs-32 mb-3 fs-xs-24 brand-black title-cart font-weight-bold">
          {{ 'recent_searches' | translate }}
        </h1>
      </div>
      <div class="row row--m-5">
        <ng-container *ngFor="let item of recentlySearch | slice: 0:10">
          <div class="col-lg-3 col-md-6 col-6 col--p-5 mb-56 mb-xs-22 position-relative">
            <div class="cat-box position-relative">
              <div class="app-card-two">
                <kwot-card-two
                  (click)="closeModal()"
                  [product]="item"
                ></kwot-card-two>
              </div>
            </div>
            <div class="text-center mt-3">
              <a
                [routerLink]="['/user/product', item.slug]"
                (click)="closeModal()"
                class="neutral_40 fs-18 font-weight-normal product_name" title="{{ item?.productName }}"
              >{{ item?.productName }}</a>
              <kwot-product-price [price]="item" tag="span" class="d-flex align-items-center justify-content-center product-price-space mt-1 brand-black fs-16 font-weight-bold" type="product" field="price" [showDiscount]="true" [platformFee]="true"></kwot-product-price>
              <div class="col-12">
                <div class="app-product-reviews mt-2 d-flex justify-content-center">
                  <kwot-product-reviews
                    [productId]="item?._id"
                    [productName]="item?.productName"
                    [productRating]="item?.rating || 0"
                    [productReviewCount]="item.ratingCount || 0"
                    [showRating]="true"
                  ></kwot-product-reviews>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
