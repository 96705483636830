import {Component, Inject, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from "@angular/platform-browser";
import {APP_CONFIG} from "@kwot/app-config";
import {select, Store} from "@ngrx/store";
import {getPolicyData} from "../../+state/vendor-buyer.selectors";
import {Subject, takeUntil} from "rxjs";
import {VendorBuyerState} from "../../+state/vendor-buyer.reducer";
import {GetPolicyData} from "../../+state/vendor-buyer.actions";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'kwot-shopping-policy',
  templateUrl: './shopping-policy.component.html',
  styleUrls: ['./shopping-policy.component.scss'],
})
export class ShoppingPolicyComponent implements OnInit {
  currentRoute: any;
  unsubscriber = new Subject();
  terms: any;
  showCrossIcon = true;
  currentTitle = '';
  currentContent = '';
  activeTab: any
  constructor(
    private router: Router,
    private titleService: Title,
    @Inject(APP_CONFIG) public appConfig: any,
    private vendorBuyerState: Store<VendorBuyerState>,
    public bsModalRef: BsModalRef,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url.split('/').filter((t) => t);
        this.currentRoute = url[1];
      }
    });
    this.subscribeToStore()
  }

  ngOnInit(): void {
    this.titleService.setTitle('Kwot - Amazing Africa. Now, delivered at your doorstep');
    if (this.currentRoute === 'cookie-policy') {
      this.terms = 'cookiePolicy'
      this.currentTitle = 'cookie_policy';
      this.showCrossIcon = false;
    }
    if (this.currentRoute === 'return-policy') {
      this.terms = 'returnPolicy'
      this.currentTitle = 'return_policy';
      this.showCrossIcon = false;
    }
    if (this.currentRoute === 'shipping-policy') {
      this.terms = 'sellerShippingPolicy'
      this.currentTitle = 'shopping_info';
      this.showCrossIcon = false;
    }
    if (this.activeTab === 'advertisingContract') {
      this.terms = 'advertisingContract'
      this.currentTitle = 'KWOT ADVERTISING TERMS AND CONDITIONS';
      this.showCrossIcon = true;
    }
    if (this.activeTab === 'affiliatesAgreement') {
      this.terms = 'affiliatesAgreement'
      this.currentTitle = 'Affiliates Agreement';
      this.showCrossIcon = true;
    }

    this.vendorBuyerState.dispatch(GetPolicyData({
      params: {
        terms: this.terms
      }
    }))
  }

  subscribeToStore() {
    this.vendorBuyerState.pipe(select(getPolicyData))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(policies => {
        if (policies) {
          this.currentContent = policies.content;
        }
      })
  }
}
