import {
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {Product} from "@kwot/data-models";
import {APP_CONFIG} from "@kwot/app-config";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'kwot-product-measurement-model',
  templateUrl: './product-measurement-model.component.html',
  styleUrls: ['./product-measurement-model.component.scss']
})
export class ProductMeasurementModelComponent implements OnInit {
  @Input() products: Product
  items: any[] = [];
  currentUser: any;

  product: any = {};

  showStep1 = true;
  showStep2 = false;

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    public bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
    this.product = {...this.products};
  }

  step1() {
    this.showStep1 = true;
    this.showStep2 = false;
  }

  step2() {
    this.showStep2 = true;
    this.showStep1 = false;
  }
}
