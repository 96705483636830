<div class="measurement-details">
  <div class="modal-header justify-content-center">
    <h3 class="modal-title fs-16 fw-bold text-center mb-0">{{ 'instructions_measurements' | translate }}</h3>
    <button type="button" class="btn-close m-0" (click)="bsModalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="account-content">
      <div class="step-header text-center">
        <div class="row">
          <div class="col-6">
            <div class="step-title step-border" [ngClass]="{'active': showStep1, 'complete': showStep2}">
              <h6 class="fs-20">{{ '1' | translate }}</h6>
              <span>
                {{ 'Open_camera' | translate }}
              </span>
            </div>
          </div>
          <div class="col-6">
            <div class="step-title" [ngClass]="{'active': showStep2}">
              <h6 class="fs-20"> {{ '2' | translate }} </h6>
              <span>
                {{ 'update' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="step-body">
        <div class="row">
          <div class="col-12">
            <ng-container *ngIf="showStep1">
              <div class="mb-4 mt-2" [ngClass]="{'d-none': showStep2}">
<!--                <p class="fs-16 text-center m-0">-->
<!--                  1. You can provide measurements by 2 methods Manually entering Or By scanning the QR code.-->
<!--                  2. To scan the QR Code-->
<!--                </p>-->
                <ol class="fs-16 m-0 list-group list-group-numbered">
                  <li>
                    {{ 'provide_measurements' | translate }}
                  </li>
                  <li>
                    {{ 'scan_the_code' | translate }}
                      <ol type="a">
                        <li>
                          {{ 'follow_screen_instructions' | translate }}
                        </li>
                        <li>
                          {{ 'click_on_screen_instructions' | translate }}
                        </li>
                      </ol>
                  </li>
                </ol>
              </div>
            </ng-container>
             <div class="mb-4 mt-2" [ngClass]="{'d-none': showStep1}">
                <div class="app-product">
                  <div class="px-32 d-flex align-items-center justify-content-between mb-3">
                    <h4 class="mb-0 font-weight-normal ff-adamina brand-black">
                      {{ 'measurement' | translate }}
                    </h4>
                    <!--    <p class="mb-0 cursor-pointer store-content-details edit" (click)="editField()" *ngIf="currentUser">-->
                    <!--      <img src="/assets/images/icons/edit.svg" class="icon"/>-->
                    <!--    </p>-->
                  </div>
                  <div class="container">
                    <div class="d-flex align-content-center justify-content-center">
                      <div id="sizeMe" class="me-3"></div>
                      <div *ngIf="loaded" class="brand-black fs-16 mt-2">{{ 'or' | translate }}</div>
                      <button *ngIf="loaded" class="btn btn-action w-auto ms-3" (click)="enterMeasurements()">{{ 'enter_manually' | translate }}</button>
                    </div>
                    <!--    <div class="form-group row mt-2" *ngIf="currentUser">-->
                    <div class="form-group mt-2">
                      <ng-container *ngFor="let item of items; let i = index">
                        <div class="d-flex" *ngIf="item.value">
                          <div class="d-flex justify-content-between">
                            <label class="ps-2 me-2">
                              {{ item.name }}
                            </label>
                          </div>
                          {{item.value}} {{i !== 0 ? items[0]?.value : ''}}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="step-footer">
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-item-center justify-content-end">
              <button class="btn-action w-auto btn-green-border px-4" (click)="step1()" *ngIf="showStep2">{{ 'back' | translate }}</button>
              <button type="submit" class="w-auto px-4 fs-14 fw-bold mx-2 btn-action btn" [ngClass]="{'btn-green-border': showStep1}" (click)="bsModalRef.hide(); save()"
                      aria-label="Close">{{showStep1 ? ('cancel' | translate) : ('done' | translate)}}</button>
              <button class="btn-action w-auto px-4" (click)="step2()" *ngIf="showStep1">{{ 'next' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
