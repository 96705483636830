<ng-container *ngIf="product?.isFavourite">
  <span class="like_icon" [ngStyle]="{backgroundColor: '#ECA264'}" (click)="removeFromWishlist()">
    <img src="assets/images/icons/fav.svg" alt=""/>
  </span>
</ng-container>
<ng-container *ngIf="!product?.isFavourite">
  <span *ngIf="wishlists && wishlists.length === 1" class="like_icon" (click)="addToWishList(wishlists[0]?._id)">
    <img src="assets/images/icons/fav.svg" alt=""/>
  </span>
  <span
    class="like_icon"
    placement="bottom right"
    containerClass="custom-popover wishlist no-last-border no-arrow"
    popoverTitle="Please select a wishlist"
    container="body"
    *ngIf="wishlists && wishlists.length > 1"
    [outsideClick]="true"
    [adaptivePosition]="false"
    [popover]="popTemplate"
  >
    <div *ngIf="isProduct">
      <img src="assets/images/icons/fav-black-border.svg" alt=""/>
    </div>
    <div *ngIf="!isProduct">
      <img src="assets/images/icons/fav.svg" alt=""/>
    </div>
    <ng-template #popTemplate>
      <div class="item" (click)="addToWishList(wishlist?._id)" *ngFor="let wishlist of wishlists">
        <span>{{wishlist?.name}}</span>
      </div>
    </ng-template>
  </span>

</ng-container>

