import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {PrivacyTermsComponent} from "../privacy-terms/privacy-terms.component";
import {ShoppingPolicyComponent} from "../shopping-policy/shopping-policy.component";
import {PaymentPolicyComponent} from "../payment-policy/payment-policy.component";
import {WindowService} from "@kwot/app-config";

@Component({
  selector: 'kwot-confirm-policy',
  templateUrl: './confirm-policy.component.html',
  styleUrls: ['./confirm-policy.component.scss']
})
export class ConfirmPolicyComponent implements OnInit {
  policies: any;
  public closeEvent: EventEmitter<any> = new EventEmitter();
  isMobile:boolean;
  constructor(
    public bsModalRef: BsModalRef,
    private modelService: BsModalService,
    private windowService: WindowService,
  ) {
    this.isMobile = this.windowService.getScreenSize() <= 767;
  }

  ngOnInit(): void {
  }

  showPrivacyTerms(activeTab: string, termsData: any) {
    const modelRef: BsModalRef = this.modelService.show(PrivacyTermsComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered',
      initialState: {
        termsData
      }
    });
    modelRef.content.activeTab = activeTab;
  }

  showCookies(policy: any = 'cookiePolicy', title: any = 'cookie_policy') {
    this.modelService.show(ShoppingPolicyComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered',
      initialState: {
        terms: policy,
        currentTitle: title
      }
    });
  }

  showPayment() {
    const modelRef: BsModalRef = this.modelService.show(PaymentPolicyComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-xl modal-dialog-centered'
    });
    modelRef.content.activeTab = true;
  }

  confirmAll() {
    this.closeEvent.emit(event);
    this.bsModalRef.hide();
  }

}
