<div class="app-product p-5">
  <div class="px-32 d-flex">
    <div>
      <h3 class="font-weight-bold ff-adamina brand-black w-100 text-center">
        {{ 'measurements' | translate }}
      </h3>
      <h5 class="font-weight-bold ff-adamina brand-black w-100">
        {{ 'product_required_measurements_note' | translate }}
      </h5>
    </div>
    <button type="button" class="btn-close" (click)="bsModalRef.hide()">
    </button>
  </div>
  <form [formGroup]="measurementForm" novalidate (ngSubmit)="(!measurementForm.invalid && measurementsDataList.length === measurementGroup.controls.length) && submitForm()">
    <div class="container">
      <div class="form-group row mt-2">
        <div class="d-flex justify-content-between">
          <label class="neutral_60 font-weight-bold mb-0 ml-2 ff-dmsans fs-12 ps-2">
            {{ 'select_metric' | translate }} *
          </label>
          <ng-container
            *ngIf="(form.metrics.dirty || form.metrics.touched) && form.metrics.errors">
            <p *ngIf="form.metrics.errors.required"
               class="text-danger font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
              {{'errors.is_required' | translate: {field: ('metrics' | translate)} }}
            </p>
          </ng-container>
        </div>
        <div class="dropdown">
          <kwot-custom-select
            class="measurement"
            [searchable]="true"
            [selectOptions]="metrics"
            selectedValue="metrics"
            [formGroup]="measurementForm"
            placeholder="{{'measurement' | translate }}">
          </kwot-custom-select>
        </div>
      </div>

      <div class="test-body">
        <ng-container *ngFor="let sizeMe of measurementGroup.controls; let i = index" formArrayName="sizeMe">
          <div class="row align-items-center mb-3">
            <ng-container [formGroupName]="i">
              <div class="col-lg-4 col-md-6">
                <label></label>
                <div class="dropdown">
                  <kwot-custom-select
                    class="measurement"
                    [searchable]="true"
                    [selectOptions]="measurementsDataList"
                    [selectedValue]="sizeMe.get('id').value"
                    (selectedValueChange)="selectedMeasurement($event, i)"
                    placeholder="{{'measurement' | translate }}">
                  </kwot-custom-select>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <label class="w-100 text-end">
                  <ng-container
                    *ngIf="(sizeMe.get('value').touched || sizeMe.get('value').dirty) && sizeMe.get('value').errors">
                    <span *ngIf="sizeMe.get('value').errors.required"
                           class="text-danger mb-0 me-2 fs-10 fw-bold">
                      {{ 'errors.is_required' | translate: {field: 'value'} }}</span>
                  </ng-container>
                </label>
                <div class="form-group mb-0 d-flex justify-content-center">
                  <input type="number" class="form-control" formControlName="value" inputRef
                         placeholder="">
                  <img src="assets/images/icons/delete.svg" class="img-fluid cursor-pointer mx-2"
                       (click)="deleteMeasurement(i)"
                       alt="Edit-icon"/>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="custom-table">
        <button class="btn-green-border d-flex align-items-center pe-4 ps-3 w-auto" *ngIf="measurementsDataList.length !== measurementGroup.controls.length"
                type="button" (click)="addMeasurement()">
          <img class="" src="/assets/images/icons/plus-icon.svg" alt="plus icon">
          {{ 'add' | translate }}
        </button>
      </div>
      <div class="w-100 ms-auto text-center">
        <button class="btn-action px-32 w-auto"
                [ngClass]="{'btn-disabled': measurementForm.invalid || measurementsDataList.length !== measurementGroup.controls.length,
                'btn-action': !measurementForm.invalid || measurementsDataList.length === measurementGroup.controls.length}"
        >
          {{'save' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
