<div class="modal-header">
  <button type="button" class="close-btn d-md-none" (click)="bsModalRef.hide()">
    <img src="assets/images/icons/back.svg  " class="img-fluid" alt="close icon"/>
  </button>
  <h5 class="modal-title text-center fs-16 fw-bold w-100">{{(isSeller ? 'rate_seller' :'rate_product') | translate}}</h5>
  <button type="button" class="close-btn d-none d-md-block" (click)="bsModalRef.hide()">
    <img src="assets/images/icons/Cross.svg" class="img-fluid" alt="close icon"/>
  </button>
  <button class="d-md-none w-auto px-3 h-auto py-2"
          [ngClass]="{'btn-action': reviewMessage && email && firstName && lastName, 'btn-disabled': !reviewMessage && !email && !firstName && !lastName}"
          (click)="postReview()">
    {{'rate' | translate}}
  </button>
</div>
<div class="modal-body">
  <div class="details">
    <div class="mb-4">
      <h3 class="main-title">{{(isSeller ? 'rate_review_seller' :'rate_review_product') | translate}}</h3>
      <h4 class="sub-title">{{productName}}</h4>
    </div>
      <div *ngIf="!currentUser">
        <div class="row">
          <div class="form-group mt-3 col-md-6">
            <div class="d-flex justify-content-between">
              <label class="m-0 fs-12 fw-bold">{{ 'first_name' | translate }}</label>
<!--              <ng-container *ngIf="errorMessage.firstName">-->
<!--                <label class="text-danger mb-0 me-2 text-end fs-10 fw-bold">-->
<!--                  {{ errorMessage.firstName | translate }}</label>-->
<!--              </ng-container>-->
            </div>
            <input
              type="text" (ngModelChange)="checkFirstName()" (blur)="checkFirstName()"
              class="form-control"
              [(ngModel)]="firstName"
              placeholder="{{ 'type_your_first_name' | translate }}"
              required
            />
          </div>
          <div class="form-group mt-3 col-md-6">
            <div class="d-flex justify-content-between">
              <label class="m-0 fs-12 fw-bold">{{ 'last_name' | translate }}</label>
<!--              <ng-container *ngIf="errorMessage.lastName">-->
<!--                <label class="text-danger mb-0 me-2 text-end fs-10 fw-bold">-->
<!--                  {{ errorMessage.lastName | translate }}</label>-->
<!--              </ng-container>-->
            </div>
            <input
              type="text" (ngModelChange)="checkLastName()" (blur)="checkLastName()"
              class="form-control"
              [(ngModel)]="lastName"
              placeholder="{{ 'type_your_last_name' | translate }}"
              required
            />
          </div>
        </div>
        <div class="form-group mt-3">
          <div class="d-flex justify-content-between">
            <label class="m-0 fs-12 fw-bold">{{ 'email' | translate }}</label>
            <ng-container *ngIf="errorMessage.email">
              <label class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
                {{ errorMessage.email | translate }}</label>
            </ng-container>
          </div>
          <input
            type="text" (ngModelChange)="checkEmail()" (blur)="checkEmail()"
            class="form-control"
            [(ngModel)]="email"
            placeholder="{{ 'type_your_email' | translate }}"
            required
          />
        </div>
      </div>

    <ul class="d-flex align-items-center justify-content-center list-unstyled">
      <li class="me-sm-4 d-flex align-items-center"
          *ngFor="let stars of ratingStars; let i = index" (click)="saveRatingValue(i)">
        <img width="40" src="assets/images/icons/star-icon.svg" (mouseover)="fillUpStar(i)" (mouseout)="fillUpBorderedStar()" alt="" *ngIf="stars.isSelected"/>
        <img src="assets/images/icons/star-border-icon.svg" (mouseover)="fillUpStar(i)" (mouseout)="fillUpBorderedStar()" width="40" alt="" *ngIf="!stars.isSelected"/>
      </li>
    </ul>

    <div class="form-group mb-3 review">
      <div class="d-flex align-items-center justify-content-between">
        <label class="mb-0 ms-2 me-auto fs-12 fw-bold">{{'review' | translate}}</label>
        <ng-container *ngIf="errorMessage.reviewMessage">
          <label class="text-danger mb-0 me-2 text-end fs-10 fw-bold">
            {{ errorMessage.reviewMessage | translate }}</label>
        </ng-container>
      </div>
      <textarea class="form-control br-16" rows="8" [(ngModel)]="reviewMessage" (ngModelChange)="checkReview()" (blur)="checkReview()"
                placeholder="{{(isSeller ? 'review_about_seller' :'review_about_product') | translate}}">
      </textarea>
    </div>
    <div>
      <div class="product-title">
        <h5 class="fs-16 fw-bold" >
          {{ 'upload_pic' | translate }}
        </h5>
      </div>
      <div class="upload-review">
        <div class="row">
          <div
            class="upload-pictures m-2"
            *ngFor="let image of images; let i = index"
          >
            <div class="img-wrapper pictures-plus m-0">
              <div
                class="user-review"
                (click)="reviewImageFile.click()"
              >
                <img
                  class="img-fluid"
                  [lazyLoad]="image"
                  defaultImage="/assets/images/icons/plus-gray.svg"
                />
              </div>
              <div
                class="delete-icon"
                *ngIf="image"
                (click)="clearImage(i)"
              >
                <img
                  class="img-fluid cursor-pointer"
                  src="/assets/images/icons/Delete-bg-white.svg"
                />
              </div>
              <input
                type="file"
                accept=".jpg, .gif, .png"
                class="d-none"
                #reviewImageFile
                (click)="reviewImageFile.value = null"
                (change)="onImageSelected($event, i)"
              />
            </div>
          </div>
          <div
            class="order-tracking ps-0 pt-3"
            *ngIf="images.length < 2"
          >
            <button
              class="btn-transparent fs-14 fw-bold"
              (click)="images.push(null)"
            >
              <img
                class="img-fluid"
                src="/assets/images/icons/plus-orange-icon.svg"
              />
              {{ 'add_more_photos' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      [ngClass]="{'btn-action': reviewMessage, 'btn-disabled': !reviewMessage || errorMessage.email}"
      [disabled]="!reviewMessage || errorMessage.email"
      class="d-none d-md-block mt-3" (click)="postReview()">
      {{'post' | translate}}
    </button>
  </div>
</div>
