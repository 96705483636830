import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicWishlistComponent} from "./dynamic-wishlist.component";
import {PopoverModule} from "ngx-bootstrap/popover";


@NgModule({
  declarations: [
    DynamicWishlistComponent
  ],
  imports: [
    CommonModule,
    PopoverModule.forRoot()
  ],
  exports: [DynamicWishlistComponent]
})
export class DynamicWishlistModule {
}
