<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end mb-3">
          <span id="close" *ngIf="activeTab">
            <img class="close-icon cursor-pointer" src="assets/images/icons/Cross.svg" alt=""
                 (click)="bsModalRef.hide()">
          </span>
        </div>
        <div class="back-btn d-flex align-items-center" *ngIf="!activeTab" [routerLink]="['/vendor', 'finances']">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer"/>
          <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab" class="text-uppercase">{{'the_kwot_payment_policy' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="privacy" class="">
            <p>{{'payment_policy_text_1' | translate}} </p>
            <p>{{'payment_policy_text_2' | translate}}</p>
            <p>{{'payment_policy_text_3' | translate}}</p>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab" class="text-uppercase">{{'vendor_bank_account_details' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="bank" class="">
            <p>{{'payment_policy_text_4' | translate}}</p>
            <ol>
              <li> {{'payment_policy_text_5' | translate}}</li>
              <li> {{'payment_policy_text_6' | translate}}</li>
              <li> {{'payment_policy_text_7' | translate}}
              </li>
            </ol>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab" class="text-uppercase">{{'the_end_to_end_vendor_payment_processing_chain' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="vendorPayment" class="">
            <p>{{'payment_policy_text_8' | translate}}</p>
            <img src="https://s3.amazonaws.com/kwot.files/payment-policy.png" class="img-fluid vendorPayment-img"
                 alt="">
            <p><b>{{'note' | translate}}:</b>{{'payment_policy_text_9' | translate}}</p>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'handling_refund_request' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="refund" class="">
            <p>{{'payment_policy_text_10' | translate}}</p>
            <img src="https://s3.amazonaws.com/kwot.files/paymeny-policy-1.png" class="img-fluid refund-img" alt="">
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'explanation_refund_requests_handled_by_seller' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="explanation" class="">
            <p>{{'payment_policy_text_11' | translate}}</p>
            <p>{{'payment_policy_text_12' | translate}}</p>
            <p>{{'payment_policy_text_13' | translate}}</p>
            <p>{{'payment_policy_text_14' | translate}}</p>
            <p>{{'payment_policy_text_15' | translate}}</p>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'explanation_refund_requests_handled_by_seller' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="payment" class="">
            <p>{{'payment_policy_text_16' | translate}}</p>
            <p>{{'payment_policy_text_17' | translate}}</p>
            <p>{{'payment_policy_text_18' | translate}}</p>
            <p>{{'payment_policy_text_19' | translate}}</p>
            <p>{{'payment_policy_text_20' | translate}}</p>
            <p>{{'payment_policy_text_21' | translate}}</p>
            <p>{{'payment_policy_text_22' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

