import {LocalstorageService} from "@kwot/app-config";

const ls = new LocalstorageService();

export function getWishlist() {
  let wishlist = ls.updateWishlist('get');
  if (!wishlist) {
    createDefaultWishlist();
    wishlist = ls.updateWishlist('get');
  }
  return wishlist;
}

export function addOrRemoveFromWishlist(wishListId: any, productId: any) {
  let wishlist = getWishlist();
  let currentWishlist = wishlist.findIndex((t: any) => t._id === wishListId);
  if (currentWishlist !== -1) {
    let item = wishlist[currentWishlist].productId.find((t: any) => {
      if (typeof t === 'string') {
        return t === (typeof productId === 'string' ? productId : productId._id)
      }
      return t._id === (typeof productId === 'string' ? productId : productId._id);
    })
    if (item) {
      wishlist[currentWishlist].productId.splice((typeof productId === 'string' ? productId : productId._id), 1);
    } else {
      wishlist[currentWishlist].productId.push(productId);
    }
  }
  ls.updateWishlist('store', wishlist);
  return wishlist;
}

export function deleteWishlist(wishlistId: any) {
  let wishlist = getWishlist();
  let index = wishlist.findIndex((t: any) => t._id === wishlistId);
  if (index !== -1) {
    wishlist.splice(index, 1);
  }
  ls.updateWishlist('store', wishlist);
  return wishlist;
}

export function createOrUpdateWishlist(name: any, _id: any = null) {
  let wishlist = getWishlist();
  if (_id) {
    let findWishlist = wishlist.findIndex((t: any) => t._id === _id);
    if (findWishlist !== -1) {
      wishlist.push({name, productId: [], _id: generateRandomString()})
    } else {
      wishlist[findWishlist].name = name;
    }
  } else {
    wishlist.push({name, productId: [], _id: generateRandomString()})
  }
  ls.updateWishlist('store', wishlist);
  return wishlist;
}

function createDefaultWishlist() {
  let data = ls.updateWishlist('get') || [];
  if (data.length <= 0) {
    data.push({name: 'default', productId: [], _id: generateRandomString()})
    ls.updateWishlist('store', data)
  }
}

function generateRandomString() {
  let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let _id = '';
  for (let i = 0; i < 24; i++) {
    _id += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return _id;
}
