import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {measurementsList, Product} from "@kwot/data-models";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";
import {DOCUMENT} from "@angular/common";
import {CustomMeasurementComponent, MeasurementState, SaveMeasurements} from "@kwot/ui";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Store} from "@ngrx/store";

@Component({
  selector: 'kwot-product-measurement',
  templateUrl: './product-measurement.component.html',
  styleUrls: ['./product-measurement.component.scss']
})
export class ProductMeasurementComponent implements OnInit, OnDestroy {
  productDetails: Product;
  orderDetails: any;
  orderId: any;
  items: any[] = [];
  currentUser: any;
  loaded = false;
  finalMeasurements: any
  showStep1 = true;
  showStep2 = false;
  orderMeasurement: any;
  ignoreSave = false;

  public closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private ls: LocalstorageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) public appConfig: any,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private measurementStore: Store<MeasurementState>,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {
  }

  removeSizerCode() {
    let doc = this.document.getElementById('SIZER_SIZE_WIDGET');
    if (doc) {
      doc.remove();
    }
  }

  ngOnInit(): void {
    if (this.showStep2) {
      this.applySizerCode();
    }
    // set order measurement
    if (this.orderDetails) {
      this.orderMeasurement = this.orderDetails?.measurements;
    }
  }

  applySizerCode() {
    if (!this.productDetails) {
      setTimeout(() => {
        this.applySizerCode();
      }, 500);
    } else {
      if (!this.document.getElementById('SizeMeURL')) {
        this.loadJsScript();
      } else {
        (window as any)?.sizer_loadData();
      }
      this.addSizerCode();
      this.applyFunctions();
    }
  }

  loadJsScript(): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this.appConfig.sizerApiUrl;
    script.id = 'SizeMeURL';
    if (this.showStep2) {
      this.renderer.appendChild(this.document.body, script);
    }
    return script;
  }

  addSizerCode() {
    const div = this.document.createElement('div');
    div.id = 'SIZER_SIZE_WIDGET';
    div.setAttribute('data-end-of-scan', 'measurements');
    div.setAttribute('data-mode', 'widget');
    div.setAttribute('data-clientid', this.appConfig.sizerClientId);
    if (this.showStep2) {
      this.document.getElementById('sizeMe').appendChild(div);
    }
  }

  applyData() {
    let sizerWindow: any = document.querySelector('#sizer_window');
    if (sizerWindow) {
      this.loaded = true;
      sizerWindow.style.zIndex = 9;
    } else {
      setTimeout(() => {
        this.applyData();
      }, 1000);
    }
  }

  applyFunctions() {
    let sizerElement: any = document.getElementById('SIZER_SIZE_WIDGET');
    if (sizerElement) {
      this.applyData();
      sizerElement.onMeasurementsReady = (measurementsData: any) => {
        this.mapItems({
          ...measurementsData.measurements,
          metrics: localStorage.getItem('measurementUnits') || 'cm',
          ...(this.currentUser && this.currentUser.measurements || {})
        });
      };
      sizerElement.onWidgetStatusChanged = (widgetStatus: any) => {
        // console.log('widgetStatus', widgetStatus);
      };
    } else {
      setTimeout(() => {
        this.applyFunctions();
      }, 1000);
    }
  }

  change(measurements: any = null) {
    if (this.productDetails && this.productDetails.sizeMe) {
      this.currentUser = this.ls.updateUserKey('get');

      // set user measurement and changed measurement value for both
      if (!this.orderDetails) {
        this.orderMeasurement = this.currentUser?.measurements;
      }

      if (measurements) {
        this.orderMeasurement = measurements;
      }

      this.items = [];
      this.items.push({name: 'Metrics', id: 'metrics'});

      // update value by get and change
      measurementsList.forEach(item => {
        if (Object.keys(this.orderMeasurement || {}).includes(item.id)) {
          this.items.push(item);
        }
      })

      if (this.orderMeasurement) {
        this.mapItems(this.orderMeasurement);
      } else {
        if (this.currentUser) {
          this.mapItems(this.currentUser.measurements);
        } else {
          this.mapItems(this.ls.updateMeasurementDetails('get') || {});
        }
      }
      if (this.currentUser && !this.ignoreSave) {
        this.measurementStore.dispatch(SaveMeasurements({body: this.finalMeasurements}));
      }
      this.ignoreSave = false;
    }
  }

  mapItems(result: any) {
    if (result && Object.keys(result).length > 0) {
      let values = this.getUpdatedValues(result)
      if (!this.currentUser) {
        this.ls.updateMeasurementDetails('store', values);
      }
      setTimeout(() => {
        this.cdr.detectChanges();
      }, 1000);
    }
  }

  getUpdatedValues(result: any) {
    this.items = this.items.map((item: any) => {
      if (result.hasOwnProperty(item.id)) {
        item.value = result[item.id];
      }
      return item;
    })
    let values: any = {};
    this.items.forEach(t => {
      values[t.id] = t.value
    })
    this.finalMeasurements = values;
    return values;
  }

  enterMeasurements() {
    const modalRef: any = this.modalService.show(CustomMeasurementComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: true,
      backdrop: 'static',
      class: 'modal-lg modal-dialog-centered z-99',
      initialState: {
        categoryMeasurements: this.productDetails.categoryId.sizeMe || this.orderDetails?.product.category.sizeMe,
        measurements: this.orderMeasurement ? this.orderMeasurement : this.finalMeasurements,
        orderId: this.orderId
      }
    });

    modalRef.content.closeEvent.subscribe((data: any) => {
      this.ignoreSave = true;
      this.orderMeasurement = data;
      this.change(data);
    })
  }

  step1() {
    this.showStep1 = true;
    this.showStep2 = false;
  }

  step2() {
    this.showStep2 = true;
    this.showStep1 = false;
    if (this.showStep2) {
      this.applySizerCode();
      this.change();
    }
  }

  ngOnDestroy() {
    this.productDetails = null;
    this.removeSizerCode();
  }

  save() {
    if (this.showStep2) {
      this.closeEvent.emit({items: this.items})
    }
  }
}
