import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductMeasurementModelComponent} from "./product-measurement-model.component";
import {SharedModule} from "@kwot/shared";



@NgModule({
  declarations: [
    ProductMeasurementModelComponent
  ],
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [ProductMeasurementModelComponent]
})
export class ProductMeasurementModelModule {
}
