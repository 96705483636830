import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {getGuestUserCheck, GetProductReviews, PostProductReview, ResetBuyerState} from '@kwot/buyer-store';
import {GetBuyerSuccess, getProductReviews} from '@kwot/buyer-store';
import {Subject, takeUntil} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {BuyerState} from '@kwot/buyer-store';
import {AuthState, getLoggedInUser} from "@kwot/auth-store";
import {AddProductReviewComponent} from "../add-product-review/add-product-review.component";
import {ToastrService} from "ngx-toastr";
import {LoaderService, UploadImageService} from "@kwot/app-config";

@Component({
  selector: 'kwot-product-reviews',
  templateUrl: './product-reviews.component.html',
  styleUrls: ['./product-reviews.component.scss']
})
export class ProductReviewsComponent implements OnInit, OnChanges, OnDestroy {

  reviews: any = [];
  reviewsCount: any = 0;
  @Input() productRating: any = 0;
  @Input() productId: string;
  @Input() productName: string;
  @Input() loadReviews = true;
  @Input() isSeller = false
  @Input() productReviewCount: any = 0
  @Input() showRating = false
  currentPage = -1;
  unsubscriber = new Subject();
  reviewAdd: any
  currentUser: any;
  guestUser: any;
  isReviewed: boolean = false;

  constructor(
    private buyerStore: Store<BuyerState>,
    private authStore: Store<AuthState>,
    private toastr: ToastrService,
    private modelService: BsModalService,
    private uploadImage: UploadImageService,
    private loaderService: LoaderService,
  ) {
    this.buyerStore.dispatch(ResetBuyerState({params: {reviews: null}}));
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.buyerStore.pipe(select(getGuestUserCheck))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(guestUser => {
        if (guestUser) {
          this.currentUser = guestUser;
        }
      })
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentUser = currentUser;
        }
      })
    if (!this.showRating) {
      this.buyerStore.pipe(select(getProductReviews))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(reviews => {
          if (reviews) {
            this.loadReviews = true;
            this.reviewsCount = reviews?.count;
            this.reviews = [...this.reviews, ...reviews?.notiData];
            this.checkReviewed(this.reviews);
          }
        })
    }
    this.buyerStore.pipe(select(GetBuyerSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success && success === '---IGNORE---') {
          if (this.reviewAdd) {
            this.reviews.unshift({
              createdAt: new Date(),
              ...(this.isSeller ? {vendorId: this.reviewAdd.productId} : {productId: this.reviewAdd.productId}),
              rating: this.reviewAdd.rating,
              review: this.reviewAdd.review,
              images: this.reviewAdd.images,
              userId: {
                _id: this.currentUser?._id,
                firstName: this.currentUser?.firstName,
                lastName: this.currentUser?.lastName,
                image: this.currentUser?.image
              }
            })
            this.reviewsCount = this.reviewsCount + 1;
            this.reviewAdd = null;
            this.checkReviewed(this.reviews);
            this.toastr.success('Your rate and review have been successfully updated!', 'Thanks for your review!');
          }
        }
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productId && changes.productId.currentValue) {
      if (this.loadReviews) {
        this.loadReviews = false;
        if (!this.showRating) {
          this.loadMoreReviews();
        }
      }
    }
  }

  ngOnInit(): void {
  }

  loadMoreReviews() {
    this.currentPage = this.currentPage + 1;
    this.buyerStore.dispatch(GetProductReviews({
      params: {
        page: this.currentPage,
        limit: 5,
        id: this.productId,
        isSeller: this.isSeller
      }
    }))
  }

  openDialog(): void {
    // if (!this.currentUser) {
    //   this.toastr.warning('Please login to review this product.');
    //   return;
    // }
    const modelRef: BsModalRef = this.modelService.show(AddProductReviewComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model full-screen-model',
      backdrop: true,
      initialState: {
        productName: this.productName,
        isSeller: this.isSeller,
        currentUser: this.currentUser
      }
    });
    modelRef.content.closeEvent.subscribe(async (result: any) => {
      if (result) {
        this.reviewAdd = {
          productId: this.productId,
          rating: result?.rating,
          review: result?.review,
        };
        let body: any = {
          ...(this.isSeller ? {vendorId: this.productId} : {productId: this.productId}),
          rating: result?.rating,
          review: result?.review,
          isSeller: this.isSeller,
        }
        let files = result.images.filter((t: any) => t);
        body.images = files;
        let folderPath = `products/${this.productName}/review/`
        for (let i = 0; i < files?.length; i++) {
          if (typeof files[i] !== 'string') {
            if (files[i]) {
              this.loaderService.show();
              body.images[i] = await this.uploadImage.uploadImageAndGetUrl({
                fileName: files[i].name,
                contentType: files[i].type,
                file: files[i],
                folderPath
              });
              this.loaderService.hide();
            }
          }
        }
        this.reviewAdd.images = body.images;
        this.buyerStore.dispatch(PostProductReview({body}))
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

  checkReviewed(reviews: any): void {
    this.isReviewed = !reviews.find((item: any) => item.userId?._id === this.currentUser?._id);
  }

}
