<div class="main-title d-flex align-items-center mb-4">
  <h4 class="mb-0 me-3 fs-xs-24 fs-32 font-weight-bold brand-black" *ngIf="!showRating">
    {{ reviewsCount }} {{ 'reviews' | translate }}
  </h4>
  <ul class="list-unstyled p-0 m-0 d-flex">
    <li [ngClass]="{'me-2': !showRating, 'me-1': showRating}"
      *ngFor="
        let stars of [].constructor(
          (productRating > 5 ? 5 : productRating) || 0
        )
      "
    >
      <img width="14.4" src="assets/images/icons/star-icon.svg" alt="" />
    </li>
    <li [ngClass]="{'me-2': !showRating, 'me-1': showRating}"
      *ngFor="
        let stars of [].constructor(
          5 - ((productRating > 5 ? 5 : productRating) || 0)
        )
      "
    >
      <img src="assets/images/icons/star-border-icon.svg" alt="" />
    </li>
  </ul>
  <span class="fs-16 font-weight-medium neutral_40 ms-1 d-flex mt-1" *ngIf="showRating && productReviewCount > 0">
    {{ productReviewCount }}
  </span>
</div>
<ng-container *ngIf="!showRating">
  <div class="review-detail mb-4" *ngIf="reviews?.length == 0">
    <h5 class="mb-0 me-3 fs-xs-24 fs-30">No reviews to display</h5>
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-6">
      <div
        class="rate-review-product d-flex mb-4 border-radius-8 bg-secondary_10 p-16 position-relative"
        *ngIf="isReviewed"
      >
        <div class="w-30 me-lg-5">
      <span class="fs-16 brand-secondary font-weight-bold line-height-150-p">
        {{
        (isSeller ? 'rate_review_seller' : 'rate_review_product') | translate
        }}
      </span>
        </div>
        <div class="">
          <ul class="list-unstyled p-0 m-0 d-flex">
            <ng-container *ngFor="let item of [0, 1, 2, 3, 4]">
              <li class="me-2">
                <img width="9.6px" src="assets/images/icons/star-icon.svg" alt="" />
              </li>
            </ng-container>
          </ul>
          <!--    <span class="fs-14 brand-secondary font-weight-medium line-height-24">{{'share_your_experience' | translate}}</span>-->
        </div>
        <div class="ms-auto ">
      <span (click)="openDialog()" class="cursor-pointer ps-3">
        <img src="assets/images/icons/orange-next.svg" alt="" />
      </span>
        </div>
      </div>
    </div>
  </div>



  <div class="list-reviews" *ngIf="reviews.length > 0">
    <ul class="list-unstyled p-0 m-0">
      <li
        class="bg-toggle-disable px-16 py-4 border-radius-8 mb-4"
        *ngFor="let item of reviews"
      >
        <div class="box-review">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <div class="user-review me-3">
                <img
                  [lazyLoad]="item?.userId?.image"
                  [defaultImage]="'assets/images/icons/user.svg'"
                  alt=""
                />
              </div>
              <div class="">
                <h6 class="fs-14 brand-black font-weight-bold mb-0">
<!--                  {{ item?.userId?.firstName }} {{ item?.userId?.lastName }}-->
                  {{ (item?.userId?.firstName || item?.userId?.lastName) ? (item?.userId?.firstName + ' ' + item?.userId?.lastName) : ('anonymous' | translate) }}
                </h6>
                <span class="neutral_40 d-block fs-12 font-weight-normal">
                {{ item?.createdAt | date: 'dd/MM/yyyy' }}
              </span>
              </div>
            </div>
            <div class="d-flex align-items-center d-none">
            <span class="neutral_80 fs-14 font-weight-medium cursor-pointer">
              <img
                class="me-3"
                src="assets/images/icons/like-icon.svg"
                alt=""
              />
              <span>{{ 'useful' | translate }}</span>
            </span>
            </div>
          </div>
          <div class="list-review-main my-2">
            <ul class="d-flex list-unstyled">
              <li *ngFor="let stars of [].constructor(item?.rating || 0)">
                <img
                  width="14.4"
                  src="assets/images/icons/star-icon.svg"
                  alt=""
                />
              </li>
              <li *ngFor="let stars of [].constructor(5 - (item?.rating || 0))">
                <img src="assets/images/icons/star-border-icon.svg" alt="" />
              </li>
            </ul>
          </div>
          <div class="review-desc">
            <p class="mb-0 brand-black fs-16 font-weight-normal">
              {{ item?.review }}
            </p>
          </div>
          <div class="row">
            <div class="review-image col-6" *ngFor="let img of item?.images">
              <img
                class="img-fluid"
                [lazyLoad]="img"
                defaultImage="assets/images/loaders/skeleton_loader.gif"
                alt=""
              />
            </div>
          </div>
          <!--        <div class="review-imgs-grp mt-3 d-none" *ngIf="item === 2">-->
          <!--          <ul class="list-unstyled m-0 p-0">-->
          <!--            <li class="d-inline-block">-->
          <!--              <div class="review-img-current">-->
          <!--                <img src="assets/images/Photo.jpg" alt=""/>-->
          <!--              </div>-->
          <!--            </li>-->
          <!--            <li class="d-inline-block">-->
          <!--              <div class="review-img-current">-->
          <!--                <img src="assets/images/Photo.jpg" alt=""/>-->
          <!--              </div>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--        </div>-->
        </div>
      </li>
      <li *ngIf="reviewsCount !== reviews.length">
      <span
        class="brand-secondary fs-16 font-weight-bold cursor-pointer"
        (click)="loadMoreReviews()"
      >
        {{ 'load_more_reviews' | translate }}
      </span>
      </li>
    </ul>
  </div>

</ng-container>
