<div class="header p-0" #toggleButton [ngClass]="{'d-none': showHeader && isMobile || isOrderSuccess}">
  <div class="row m-0">
    <div class="col-12 m-0 p-0">
      <nav
        class="navbar buyer-header navbar-expand-lg w-100 d-block"
        [ngClass]="{ 'bg-white': isWhite && !isMobile, '': !isWhite }"
      >
        <div class="top-header d-flex align-items-center justify-content-lg-between">
          <div
            class="d-flex align-items-center justify-content-lg-between left-menu"
            [ngClass]="{'sidebar-open': isShowNavbar}"
          >
            <button *ngIf="isMobile"
                    (click)="showNavbar(true)"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    data-bs-target="#navbarNav"
                    data-bs-toggle="collapse"
                    id="nav-bar-button-toggler"
                    type="button"
            >
              <i class="fa fa-bars" aria-hidden="true"></i>
            </button>

            <a class="navbar-brand" [routerLink]="['/user', 'home']">
              <img
                src="assets/images/{{ isMobile ? 'Logo-black' : (isWhite ? 'Logo' : 'Logo') }}.svg"
                alt=""
              />
              <img class="logo-black img-fluid"
                   src="assets/images/Logo.svg"
                   alt=""
              />
            </a>

            <div class="d-flex align-items-center justify-content-end d-lg-none mobile-section" *ngIf="isMobile">
              <button
                class="btn btn-search-icon-set search-mobile-btn"
                (click)="isMobile ? search($event) : null"
              >
                <img
                  src="assets/images/icons/search_icon_svg.svg"
                  alt=" "
                />
              </button>

              <div class="language-dropdown">
                <kwot-custom-select
                  class="select-language"
                  [searchable]="false"
                  [selectedValue]="selectedLanguage"
                  [selectOptions]="languagesShortList"
                  (optionSelected)="changeLanguage($event)"
                >
                </kwot-custom-select>
              </div>

              <div class="currency-dropdown">
                <kwot-custom-select
                  class="select-currency"
                  [searchable]="false"
                  [selectedValue]="selectedCurrency"
                  [selectOptions]="currencySymbolList"
                  (optionSelected)="changeCurrency($event)"
                >
                </kwot-custom-select>
              </div>
            </div>

            <ng-template #subCats let-category="category" *ngIf="isMobile">
              <ng-container *ngIf="category">
                <li
                  class="sub-item"
                  *ngFor="let subcategory of category.children; let i = index"
                >
                  <a
                    href="#"
                    class="sub-link"
                    *ngIf="!isMobile"
                    [routerLink]="['/user/category', subcategory?.value?.slug]"
                  >{{ subcategory?.text }}
                    <i
                      class="fa fa-chevron-right"
                      aria-hidden="true"
                      [ngClass]="{
                      'hidden-menu': subcategory.children.length <= 0,
                      '': subcategory.children.length > 0
                    }"
                    ></i>
                  </a>
                  <a
                    class="sub-link"
                    [ngClass]="{ result: subcategory.isSelected }"
                    *ngIf="isMobile"
                  >
                  <span (click)="getChildren(categories, subcategory.text)"
                        [routerLink]="['/user/category', subcategory?.value?.slug]">
                    {{ subcategory?.text }}
                  </span>
                    <i
                      class="fa fa-plus plus-icon"
                      aria-hidden="true"
                      (click)="$event.stopPropagation(); getChildren(categories, subcategory.text)"
                      [ngClass]="{
                      'hidden-menu': subcategory.children.length <= 0,
                      'display-menu': subcategory.children.length > 0
                    }"
                    ></i>
                    <i
                      aria-hidden="true"
                      (click)="$event.stopPropagation(); getChildren(categories, subcategory.text)"
                      [ngClass]="{
                      'hidden-menu': subcategory.children.length <= 0,
                      'display-menu fa-solid fa-minus minus-icon': subcategory.children.length > 0
                    }"
                    ></i>
                  </a>
                  <ul
                    *ngIf="subcategory.children.length > 0"
                    [ngClass]="{
                    'hidden-menu': subcategory.children.length <= 0,
                    'display-menu': subcategory.children.length > 0
                  }"
                    class="nav-menu"
                  >
                    <li class="nav-item nav-sub-title" *ngIf="!isMobile">
                      {{ subcategory?.text }}
                    </li>
                    <ng-container
                      [ngTemplateOutlet]="subCats"
                      [ngTemplateOutletContext]="{ category: subcategory }"
                    ></ng-container>
                  </ul>
                </li>
              </ng-container>
            </ng-template>
            <div class="mobile-header-background" >
              <div class="collapse navbar-collapse" id="navbarNav" *ngIf="isMobile">
                <ul class="navbar-nav me-auto align-items-lg-center">
                  <li class="nav-item" *ngIf="isMobile">
                    <button
                      type="button"
                      (click)="showNavbar(false)"
                      class="btn-close m-0 fs-20 float-end p-0"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></button>
                  </li>
                  <li
                    class="nav-item"
                    *ngFor="let category of categories | slice: 0:7; let i = index"
                  >
                    <a
                      class="nav-link"
                      [ngClass]="{
                    'is-white': isWhite,
                    'hidden-arrow': category.children.length <= 0,
                    'display-arrow': category.children.length > 0
                  }"
                      *ngIf="!isMobile"
                      [routerLink]="['/user/category', category?.value?.slug]"
                    >{{ category.text }}
                      <span
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></span>
                    </a>

                    <a
                      class="nav-link"
                      [ngClass]="{
                    'is-white': isWhite,
                    'hidden-arrow': category.children.length <= 0,
                    'display-arrow': category.children.length > 0,
                    result: category.isSelected
                  }"
                      *ngIf="isMobile"
                    >
                    <span [routerLink]="category.children.length <= 0 ? ['/user/category', category?.value?.slug] : []"
                          (click)="category.children.length <= 0 ? $event.preventDefault() : $event.stopPropagation()">
                      {{ category?.text }}
                    </span>
                      <span
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></span>
                      <i
                        class="fa fa-plus plus-icon"
                        aria-hidden="true"
                        (click)="$event.stopPropagation(); navigate(i)"
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></i>
                      <i
                        class="fa-solid fa-minus minus-icon"
                        aria-hidden="true"
                        (click)="$event.stopPropagation(); navigate(i)"
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></i>
                    </a>
                    <div
                      class="mega-menu"
                      *ngIf="category.children.length > 0"
                      [ngClass]="{
                    'hidden-menu': category.children.length <= 0,
                    'display-menu': category.children.length > 0
                  }"
                    >
                      <ul
                        class="nav-submenu"
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      >
                        <li
                          class="nav-item nav-title"
                          [ngClass]="{
                        'hidden-menu': category.children.length <= 0,
                        'display-menu': category.children.length > 0
                      }"
                          [routerLink]="['/user/category', category?.value?.slug]"
                        > {{ isMobile ? 'See all' : category.text }}
                        </li>
                        <ng-container
                          [ngTemplateOutlet]="subCats"
                          [ngTemplateOutletContext]="{ category: category }"
                        ></ng-container>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul class="navbar-nav right-m w-100 align-items-center right-navbar">
            <li class="search-product-btn d-lg-block d-none">
              <form class="position-relative" action="">
                <input
                  class="form-control"
                  [ngClass]="{ 'is-white': isWhite }"
                  placeholder="search"
                  value=""
                  [(ngModel)]="searchInput"
                  (keyup)="search($event)"
                  [ngModelOptions]="{ standalone: true }"
                />
                <button
                  class="btn btn-search-icon-set"
                  (click)="isMobile ? search($event) : null"
                >
                  <img
                    src="assets/images/icons/{{
                    isWhite ? 'gray-search-icons' : 'search-icon'
                  }}.svg"
                    alt=" "
                  />
                </button>
              </form>
            </li>
            <li class="position-relative">
              <div class="language-dropdown">
                <kwot-custom-select
                  class="select-language"
                  [searchable]="false"
                  [selectedValue]="selectedLanguage"
                  [selectOptions]="languages"
                  (optionSelected)="changeLanguage($event)"
                >
                </kwot-custom-select>
              </div>
            </li >
            <li class="position-relative">
              <div class="currency-dropdown">
                <kwot-custom-select
                  class="currency-data"
                  [searchable]="false"
                  [selectedValue]="selectedCurrency"
                  [selectOptions]="currencyList"
                  (optionSelected)="changeCurrency($event)"
                >
                </kwot-custom-select>
              </div>
            </li>
            <li class="position-relative">
              <a
                [ngClass]="{ 'is-white': isWhite }"
                [routerLink]="['/user', 'cart']"
              >
                <p class="badge cart-qty" *ngIf="cartQuantity">
                  <span>{{ cartQuantity > 99 ? '99+' : cartQuantity }}</span>
                </p>
                <img
                  src="assets/images/icons/{{
                  isWhite ? 'gray-cart' : 'gray-cart'
                }}.svg"
                  alt=""
                />
              </a>
            </li>
            <li class="position-relative">
              <a
                [ngClass]="{ 'is-white': isWhite }"
                [routerLink]="['/user', 'wish-list']"
              >
                <p class="badge cart-qty" *ngIf="productLength">
                  <span>{{ productLength > 99 ? '99+' : productLength }}</span>
                </p>
                <img
                  src="assets/images/icons/{{
                  isWhite ? 'gray-fav' : 'gray-fav'
                }}.svg"
                  alt=""
                />
              </a>
            </li>
            <div class="dropdown profile-drop relative-pos">
              <div
                class="user-profile"
                id="profile-dropdown"
                data-bs-toggle="dropdown"
                [ngClass]="{ 'no-image': !currentUser?.image }"
              >
                <a *ngIf="currentUser?.image">
                  <img
                    [lazyLoad]="currentUser?.image"
                    defaultImage="assets/images/loaders/skeleton_loader.gif"
                    class="user-logo"
                  />
                </a>
                <a *ngIf="!currentUser?.image">
                  <img
                    src="assets/images/icons/{{
                    isWhite ? 'gray-user' : 'gray-user'
                  }}.svg"
                    class="user-logo"
                  />
                </a>
              </div>
              <div
                class="dropdown-menu notification_drop_down"
                id="account-menu"
                aria-labelledby="profile-dropdown"
              >
                <ul [ngClass]="{ 'no-border': !currentUser }">
                  <ng-container *ngIf="viewNotification; else showOptions">
                    <li>
                      <div class="d-flex align-items-center">
                      <span
                        class="cursor-pointer search"
                        (click)="viewNotification = null"
                      >
                        <img src="assets/images/icons/back.svg" alt="" />
                      </span>
                        <div class="w-100 d-flex justify-content-center">
                          <div class="title fs-16 fw-bold">{{ 'notifications' | translate }}</div>
                        </div>
                      </div>
                      <div class="notification-drop-menu padding-none">
                        <h4 class="fw-bold fs-16">
                          {{ viewNotification?.title || ('kwot' | translate) }}
                        </h4>
                        <p class="fs-10 mb-2">
                          {{
                          viewNotification?.createdAt
                            | date: 'dd/MM/yyyy hh:mm aa'
                          }}
                        </p>
                        <h6 class="fs-14">
                          {{ viewNotification?.message }}
                        </h6>
                        <a
                          *ngIf="viewNotification?.link"
                          href="{{ viewNotification?.link }}"
                        >
                        <span class="fs-14">{{
                          getText(viewNotification?.title)
                          }}</span>
                        </a>
                      </div>
                    </li>
                  </ng-container>
                  <ng-template #showOptions>
                    <ng-container *ngIf="currentUser; else signIn">
                      <li>
                        <a [routerLink]="['/user', 'profile']">
                          <img
                            class="dark-icon me-3"
                            src="/assets/images/icons/user-profile.svg"
                            alt=""
                          />
                          {{ 'my_account' | translate }}
                        </a>
                      </li>
                      <li class="notification">
                        <ng-container
                          *ngIf="
                          notifications.length <= 0;
                          else showNotifications
                        "
                        >
                          <div class="title">
                            <h5 class="fs-12 fw-bold subtitle">
                              {{ 'notifications' | translate }}
                            </h5>
                          </div>
                          <div class="no-notification">
                            <img
                              class="dark-icon me-3"
                              src="/assets/images/icons/empty-box.svg"
                              alt=""
                            />
                            <h6
                              class="fw-medium subtitle fw-bold subtitle-margin"
                            >
                              {{ 'no_notifications' | translate }}
                            </h6>
                          </div>
                        </ng-container>
                        <ng-template #showNotifications>
                          <div class="title">
                            <h5 class="fs-12 fw-bold subtitle">{{ 'notifications' | translate }}</h5>
                          </div>
                          <div
                            class="notification-drop-menu cursor-pointer"
                            (click)="showNotification(notification)"
                            [ngClass]="{
                            unread: !notification?.isRead,
                            read: notification?.isRead
                          }"
                            *ngFor="let notification of notifications"
                          >
                            <h4 class="fw-bold fs-16">
                              <span></span>
                              {{ notification?.title || ('kwot' | translate) }}
                            </h4>
                            <p class="fs-10 mb-2">
                              {{
                              notification?.createdAt
                                | date: 'dd/MM/yyyy hh:mm aa'
                              }}
                            </p>
                            <h6 class="fs-12 ellipse-description mb-0">
                              {{ notification?.message }}
                            </h6>
                            <!--                          <a-->
                            <!--                            *ngIf="notification?.link"-->
                            <!--                            href="{{ notification?.link }}"-->
                            <!--                          >-->
                            <!--                            <span class="fs-14">{{-->
                            <!--                              getText(notification.title)-->
                            <!--                            }}</span>-->
                            <!--                          </a>-->
                          </div>
                          <div
                            class="load-more"
                            (click)="loadNotifications()"
                            *ngIf="totalNotifications !== notifications.length"
                          >
                            {{ 'load_more' | translate }}
                          </div>
                        </ng-template>
                      </li>
                      <li class="sign-out cursor-pointer" (click)="logoutUser()">
                        <a>{{ 'sign_out' | translate }}</a>
                      </li>
                    </ng-container>
                    <ng-template #signIn>
                      <li>
                        <a [routerLink]="['/', appConfig.type, 'become-seller']">
                          {{ 'register' | translate }} ({{ 'seller' | translate }})
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/user', 'login']">
                          <a>{{ 'sign_in' | translate }} ({{ 'buyer' | translate }})</a>
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/user', 'login']">
                          <a>{{ 'sign_in' | translate }} ({{ 'affiliate' | translate }})</a>
                        </a>
                      </li>
                    </ng-template>
                  </ng-template>
                </ul>
              </div>
            </div>
          </ul>
        </div>
        <div class="bottom-header">
          <div
            class="d-flex align-items-center justify-content-lg-between left-menu pt-lg-1 pt-0"
            [ngClass]="{'sidebar-open': isShowNavbar}"
          >
            <!--          <button-->
            <!--            (click)="showNavbar(true)"-->
            <!--            aria-controls="navbarNav"-->
            <!--            aria-expanded="false"-->
            <!--            aria-label="Toggle navigation"-->
            <!--            class="navbar-toggler"-->
            <!--            data-bs-target="#navbarNav"-->
            <!--            data-bs-toggle="collapse"-->
            <!--            id="nav-bar-button-toggler"-->
            <!--            type="button"-->
            <!--          >-->
            <!--            <i class="fa fa-bars" aria-hidden="true"></i>-->
            <!--          </button>-->

            <!--          <a class="navbar-brand" [routerLink]="['/user', 'home']">-->
            <!--            <img-->
            <!--              src="assets/images/{{ isMobile ? 'Logo-black' : (isWhite ? 'Logo' : 'Logo') }}.svg"-->
            <!--              alt=""-->
            <!--            />-->
            <!--            <img class="logo-black img-fluid"-->
            <!--                 src="assets/images/Logo.svg"-->
            <!--                 alt=""-->
            <!--            />-->
            <!--          </a>-->
            <ng-template #subCats let-category="category">
              <ng-container *ngIf="category">
                <li
                  class="sub-item"
                  *ngFor="let subcategory of category.children; let i = index"
                >
                  <a
                    href="#"
                    class="sub-link"
                    *ngIf="!isMobile"
                    [routerLink]="['/user/category', subcategory?.value?.slug]"
                  >{{ subcategory?.text }}
                    <i
                      class="fa fa-chevron-right"
                      aria-hidden="true"
                      [ngClass]="{
                      'hidden-menu': subcategory.children.length <= 0,
                      '': subcategory.children.length > 0
                    }"
                    ></i>
                  </a>
                  <a
                    class="sub-link"
                    [ngClass]="{ result: subcategory.isSelected }"
                    *ngIf="isMobile"
                  >
                  <span (click)="getChildren(categories, subcategory.text)"
                        [routerLink]="['/user/category', subcategory?.value?.slug]">
                    {{ subcategory?.text }}
                  </span>
                    <i
                      class="fa fa-plus plus-icon"
                      aria-hidden="true"
                      (click)="$event.stopPropagation(); getChildren(categories, subcategory.text)"
                      [ngClass]="{
                      'hidden-menu': subcategory.children.length <= 0,
                      'display-menu': subcategory.children.length > 0
                    }"
                    ></i>
                    <i
                      aria-hidden="true"
                      (click)="$event.stopPropagation(); getChildren(categories, subcategory.text)"
                      [ngClass]="{
                      'hidden-menu': subcategory.children.length <= 0,
                      'display-menu fa-solid fa-minus minus-icon': subcategory.children.length > 0
                    }"
                    ></i>
                  </a>
                  <ul
                    *ngIf="subcategory.children.length > 0"
                    [ngClass]="{
                    'hidden-menu': subcategory.children.length <= 0,
                    'display-menu': subcategory.children.length > 0
                  }"
                    class="nav-menu"
                  >
                    <li class="nav-item nav-sub-title" *ngIf="!isMobile">
                      {{ subcategory?.text }}
                    </li>
                    <ng-container
                      [ngTemplateOutlet]="subCats"
                      [ngTemplateOutletContext]="{ category: subcategory }"
                    ></ng-container>
                  </ul>
                </li>
              </ng-container>
            </ng-template>
            <div class="mobile-header-background">
              <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav me-auto align-items-lg-center">
                  <li class="nav-item" *ngIf="isMobile">
                    <button
                      type="button"
                      (click)="showNavbar(false)"
                      class="btn-close m-0 fs-20 float-end p-0"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></button>
                  </li>
                  <li
                    class="nav-item"
                    *ngFor="let category of categories | slice: 0:7; let i = index"
                  >
                    <a
                      class="nav-link"
                      [ngClass]="{
                    'is-white': isWhite,
                    'hidden-arrow': category.children.length <= 0,
                    'display-arrow': category.children.length > 0
                  }"
                      *ngIf="!isMobile"
                      [routerLink]="['/user/category', category?.value?.slug]"
                    >{{ category.text }}
                      <span
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></span>
                    </a>

                    <a
                      class="nav-link"
                      [ngClass]="{
                    'is-white': isWhite,
                    'hidden-arrow': category.children.length <= 0,
                    'display-arrow': category.children.length > 0,
                    result: category.isSelected
                  }"
                      *ngIf="isMobile"
                    >
                    <span [routerLink]="category.children.length <= 0 ? ['/user/category', category?.value?.slug] : []"
                          (click)="category.children.length <= 0 ? $event.preventDefault() : $event.stopPropagation()">
                      {{ category?.text }}
                    </span>
                      <span
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></span>
                      <i
                        class="fa fa-plus plus-icon"
                        aria-hidden="true"
                        (click)="$event.stopPropagation(); navigate(i)"
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></i>
                      <i
                        class="fa-solid fa-minus minus-icon"
                        aria-hidden="true"
                        (click)="$event.stopPropagation(); navigate(i)"
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      ></i>
                    </a>
                    <div
                      class="mega-menu"
                      *ngIf="category.children.length > 0"
                      [ngClass]="{
                    'hidden-menu': category.children.length <= 0,
                    'display-menu': category.children.length > 0
                  }"
                    >
                      <ul
                        class="nav-submenu"
                        [ngClass]="{
                      'hidden-menu': category.children.length <= 0,
                      'display-menu': category.children.length > 0
                    }"
                      >
                        <li
                          class="nav-item nav-title"
                          [ngClass]="{
                        'hidden-menu': category.children.length <= 0,
                        'display-menu': category.children.length > 0
                      }"
                          [routerLink]="['/user/category', category?.value?.slug]"
                        > {{ isMobile ? 'See all' : category.text }}
                        </li>
                        <ng-container
                          [ngTemplateOutlet]="subCats"
                          [ngTemplateOutletContext]="{ category: category }"
                        ></ng-container>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
