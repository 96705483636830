import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'kwot-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'buyer';
  subscription: any;

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
