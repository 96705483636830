import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
// import Swiper core and required modules
import SwiperCore, {Navigation, Pagination} from "swiper";
import {APP_CONFIG, UploadImageService} from "@kwot/app-config";
import {Router} from "@angular/router";
import {BuyerState, CreateAdsTransaction} from "@kwot/buyer-store";
import {Store} from "@ngrx/store";

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'kwot-card-two',
  templateUrl: './card-two.component.html',
  styleUrls: ['./card-two.component.scss']
})
export class CardTwoComponent implements OnInit {

  @Input() product?: any;
  @Input() type?: any = 'product';
  @Output() click = new EventEmitter()

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    public router: Router,
    private uploadImage: UploadImageService,
    private buyerStore: Store<BuyerState>
  ) {
  }

  ngOnInit(): void {
  }

  getImage(item: any, size: any) {
    return this.uploadImage.getImages(item.coverImage, size);
  }

  getExtraImage(image: any, size: any) {
    return this.uploadImage.getImages(image, size);
  }

  redirect(item: any) {
    this.buyerStore.dispatch(CreateAdsTransaction({
      body: {
        adsId: [item._id],
        type: 'click',
        ...(item.vendorId ? {productId: item.productId} : {})
      },
    }))
    if (!item.vendorId) {
      window.open('https://' + item.websiteUrl.replace('https://', '').replace('http://', ''), '_blank');
    }
    if (item.vendorId) {
      window.open(`user/product/${item.productSlug}?sponsored=${item._id}`, '_blank');
    }
  }

  routeClick(id: any) {
    if (this.appConfig.type === 'admin') {
      return;
    }
    this.click.emit(true)
    this.router.navigate(['/', this.appConfig.type, 'product', id])
  }
}
