import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG} from "@kwot/app-config";

@Injectable({
  providedIn: "root"
})
export class MeasurementsService {
  ApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.ApiUrl = `${this.appConfig.apiUrl}/`;
  }

  saveMeasurements(body: any) {
    return this.http.post(`${this.ApiUrl}${this.appConfig.type}/saveMeasurements`, body);
  }

}
