import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Product} from "@kwot/data-models";
import {select, Store} from "@ngrx/store";
import {BuyerState} from "@kwot/buyer-store";
import {GetWishListDetials} from "@kwot/buyer-store";
import {Subject, takeUntil} from "rxjs";
import {AddProductWishList, RemoveProductWishList, ResetBuyerState} from "@kwot/buyer-store";
import {ToastrService} from "ngx-toastr";
import {AuthState, getLoggedInUser} from "@kwot/auth-store";
import {addOrRemoveFromWishlist, getWishlist} from "../../../../../buyer-shared/src/lib/utils/wishlistManagement";

@Component({
  selector: 'kwot-dynamic-wishlist',
  templateUrl: './dynamic-wishlist.component.html',
  styleUrls: ['./dynamic-wishlist.component.scss']
})
export class DynamicWishlistComponent implements OnInit, OnDestroy, OnChanges {

  @Input() product: Product;
  @Output() wishlistAdded = new EventEmitter();
  wishlists: any[] = [];
  unsubscriber = new Subject();
  isAdd = false;
  isRemove = false;
  isProduct = false;
  currentuser: any;

  constructor(
    private buyerStore: Store<BuyerState>,
    private toastr: ToastrService,
    private authStore: Store<AuthState>
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.subscribeToStore();
    if (!this.currentuser) {
      if (this.product) {
        this.product = {
          ...this.product,
          isFavourite: this.isFavorite()
        };
      }
    }
  }

  isFavorite() {
    let findWishlist = getWishlist().find((item: any) => {
      return item.productId.find((t: any) => {
        if (typeof t === 'string') {
          return t === this.product._id
        }
        return t._id === this.product._id
      })
    });
    return !!findWishlist;
  }

  subscribeToStore() {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentuser = currentUser;
        }
      })
    this.buyerStore
      .pipe(select(GetWishListDetials))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((wishList) => {
        if (wishList && this.currentuser) {
          this.wishlists = wishList;
        } else {
          this.wishlists = getWishlist();
        }
        if (this.isAdd) {
          this.isAdd = false;

          this.product = {
            ...this.product,
            isFavourite: true
          };
        }
        if (this.isRemove) {
          this.isRemove = false;

          this.product = {
            ...this.product,
            isFavourite: false
          };
        }
      });

    this.isProduct = Object.keys(this.product).length === 0;

  }

  addToWishList(wishlistId: any = null) {
    this.isAdd = true;
    if (this.currentuser) {
      const selectedProduct = {id: wishlistId, productId: this.product._id};
      this.buyerStore.dispatch(AddProductWishList({selectedProduct}));
    } else {
      this.wishlists = addOrRemoveFromWishlist(wishlistId, this.product);
      this.buyerStore.dispatch(ResetBuyerState({params: {wishList: this.wishlists}}))
    }
  }

  removeFromWishlist() {
    this.isRemove = true;
    let wishlistId;
    let findWishlist = this.wishlists.find(item => item.productId.find((t: any) => t._id === this.product._id));
    if (findWishlist) {
      wishlistId = findWishlist?._id;
    }
    this.toastr.success(`Product removed from ${findWishlist.name}`);
    if (this.currentuser) {
      const selectedProduct = {id: wishlistId, productId: this.product._id};
      this.buyerStore.dispatch(RemoveProductWishList({selectedProduct}));
    } else {
      this.wishlists = addOrRemoveFromWishlist(wishlistId, this.product);
      this.buyerStore.dispatch(ResetBuyerState({params: {wishList: this.wishlists}}))
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
}
