import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Subject, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {BuyerState, GetError} from "@kwot/buyer-store";
import {ToastrService} from "ngx-toastr";
import {LocalstorageService} from "@kwot/app-config";
import {AuthState, getAuthError, getLoggedInUser, Login} from "@kwot/auth-store";
import {Title} from "@angular/platform-browser";
import {User} from "@kwot/data-models";

@Component({
  selector: 'kwot-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {
  currentuser: any;
  unsubscriber = new Subject();
  loginForm: UntypedFormGroup;
  isRegisterWithPhoneNumber = false
  showPassword = false
  isCart: boolean = false;
  @Input() isB2BRfq: boolean = false;

  public closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private authStore: Store<AuthState>,
    private buyerStore: Store<BuyerState>,
    private toastr: ToastrService,
    private localStorageService: LocalstorageService,
    private titleService: Title,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.currentuser = this.localStorageService.updateUserKey('get');
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/))]],
      phone: [''],
      password: ['', Validators.required],
    })
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentuser = currentUser;
          this.localStorageService.updateUserKey('store', currentUser);
          if (this.currentuser) {
            this.closeEvent.emit(this.currentuser);
            this.bsModalRef.hide();
          }
        }
      })

    this.authStore.pipe(select(getAuthError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error && error !== '---IGNORE---') {
          this.toastr.error(error);
        }
      })

    this.buyerStore.pipe(select(GetError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error && error !== '---IGNORE---') {
          this.toastr.error(error);
        }
      })
    this.titleService.setTitle('Kwot - Cart');
  }

  get form() {
    return this.loginForm.controls;
  }

  changeLoginType(isWithPhone: boolean) {
    this.isRegisterWithPhoneNumber = isWithPhone;
    if (isWithPhone) {
      this.loginForm.patchValue({email: '', phone: ''});
      this.loginForm.get('email')?.clearValidators();
      this.loginForm.get('phone')?.setValidators(Validators.required);
    } else {
      this.loginForm.patchValue({email: '', phone: ''});
      this.loginForm.get('phone')?.clearValidators();
      this.loginForm.get('email')?.setValidators([Validators.required, Validators.pattern(RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/))]);
    }
    this.loginForm.get('email')?.updateValueAndValidity();
    this.loginForm.get('phone')?.updateValueAndValidity();
  }

  submitForm() {
    if (this.loginForm.invalid) {
      return;
    }
    const formValues = {...this.loginForm.value};
    const body: User | any = {
      ...(this.isRegisterWithPhoneNumber ? {
        phone: (formValues.phone?.number || '').replace(/\s/g, ''),
      } : {email: formValues.email}),
      password: formValues.password,
    };
    // this.buyerStore.dispatch(Login({body: body, showLoader: true}));
    this.authStore.dispatch(Login({user: body}));
  }

  close() {
    if (this.isCart) {
      this.closeEvent.emit({addToCart: true});
    }

    this.bsModalRef?.hide()
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
}
