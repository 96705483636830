import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BuyerHeaderComponent} from "./components/buyer-header/buyer-header.component";
import {SharedModule} from "@kwot/shared";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {ProductMeasurementComponent} from "./components/product-mesurement/product-measurement.component";
import {DynamicWishlistModule, MeasurementModule} from "@kwot/ui";
import {SelectModule} from "@kwot/select";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    DynamicWishlistModule,
    MeasurementModule,
    SelectModule
  ],
  declarations: [BuyerHeaderComponent, ProductMeasurementComponent],
  exports: [BuyerHeaderComponent, ProductMeasurementComponent]
})
export class BuyerLayoutModule {
}
