import {createAction, props} from "@ngrx/store";

const MeasurementActions = {
  SAVE_MEASUREMENTS: '[MEASUREMENT] Save Measurements',
  SAVE_SAVE_MEASUREMENTS_SUCCESS: '[MEASUREMENT] Save Measurements Success',
  SAVE_SAVE_MEASUREMENTS_ERROR: '[MEASUREMENT] Save Measurements Error',
  RESET_MEASUREMENT_STATE: '[MEASUREMENT] Reset Measurement State'
}

export const SaveMeasurements = createAction(MeasurementActions.SAVE_MEASUREMENTS, props<{ body: any }>());
export const SaveMeasurementsSuccess = createAction(MeasurementActions.SAVE_SAVE_MEASUREMENTS_SUCCESS, props<{ measurements: any }>());
export const SaveMeasurementsError = createAction(MeasurementActions.SAVE_SAVE_MEASUREMENTS_ERROR, props<{ error: string }>());

export const ResetMeasurementState = createAction(MeasurementActions.RESET_MEASUREMENT_STATE, (params: any = {}) => params);
